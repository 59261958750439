// Core Imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
//import { MaterialModule } from '@angular/material';
import {DndModule} from 'ng2-dnd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Component imports
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import { LayerComponent } from './layers-list/layer/layer.component';

import { LoaderComponent } from './loader/loader.component';
import { ColorbarComponent } from './colorbar/colorbar.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LayersListComponent } from './layers-list/layers-list.component';
import { LayerSettingsComponent } from './layers-list/layer-settings/layer-settings.component';
import { LogoComponent } from './logo/logo.component';
import { MasterLayerSelectorComponent } from './master-layer-selector/master-layer-selector.component';
import { LayerContainerComponent } from './layer-container/layer-container.component';
// Services
// import { MapService } from './map/map.service';
import { DatasetsService } from './datasets.service';
import { MapService } from './shared/map.service';
import { CategoriesService } from './shared/categories.service';
import { LayersService } from './shared/layers.service';
import { UtilitiesService } from './shared/utilities.service';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { UserPolicyComponent } from './user-disclaimer/user-policy/user-policy.component';
import { ChartWindowComponent } from './chart-window/chart-window.component';
import { TimelineService } from './shared/timeline.service';
import { GoogleAnalyticsService } from './shared/google-analytics.service';
// JS
import 'hammerjs';
import {} from 'jasmine';
import { SeriesChartComponent } from './series-chart/series-chart.component';
import { MapTooltipComponent } from './map/map-tooltip/map-tooltip.component';
import { InfoGuideComponent } from './info-guide/info-guide.component';
import { QuickInfoBoxComponent } from './quick-info-box/quick-info-box.component';
import { MapInfoTooltipComponent } from './map-info-tooltip/map-info-tooltip.component';
//Material
import { MatToolbarModule, MatIconModule, MatSidenavModule, MatTabsModule, MatFormFieldModule, MatProgressSpinnerModule, MatCommonModule,
  MatRippleModule, MatButtonModule, MatCheckboxModule, MatSelectModule, MatSliderModule, MatListModule, MatCardModule, MatChipsModule, MatProgressBarModule,
  MatInputModule, MatSnackBarModule, MatMenuModule, MatDialogModule, MatAutocompleteModule, MatDatepickerModule, MatExpansionModule, MatTableModule,
  MatPaginatorModule, MatNativeDateModule, MatSlideToggleModule, MatRadioModule, MatTreeModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ParentCategoryService } from './shared';
declare var require: any;


export const firebaseConfig = {
  apiKey: 'AIzaSyBQeFls_sWSmApHIhGMtJClrpprtWZ8isQ',
  authDomain: 'coastnet-32f78.firebaseapp.com',
  databaseURL: 'https://coastnet-32f78.firebaseio.com',
  projectId: 'coastnet-32f78',
  storageBucket: 'coastnet-32f78.appspot.com',
  messagingSenderId: '300543830326',
  appId: '1:300543830326:web:9d9fb1bd1518e7c3'
};

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    LoaderComponent,
    ColorbarComponent,
    LogoComponent,
    TimelineComponent,
    LayerComponent,
    LayerComponent,
    LayerSettingsComponent,
    LayersListComponent,
    MasterLayerSelectorComponent,
    DisclaimerComponent,
    ChartWindowComponent,
    LayerContainerComponent,
    SeriesChartComponent,
    MapTooltipComponent,
    InfoGuideComponent,
    QuickInfoBoxComponent,
    MapInfoTooltipComponent,
    UserPolicyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    //MaterialModule,
    DndModule.forRoot(),
    MatProgressSpinnerModule,
    MatCommonModule,
    MatRippleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSliderModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatProgressBarModule,
    MatInputModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatTreeModule,
    HttpClientModule
  ],
  entryComponents: [LayerSettingsComponent, DisclaimerComponent, UserPolicyComponent, ChartWindowComponent, SeriesChartComponent, QuickInfoBoxComponent],
  providers: [LayersService, MapService, DatasetsService, ParentCategoryService, CategoriesService, UtilitiesService, TimelineService, GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
