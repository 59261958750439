import { Category } from './category';

export class ParentCategory {
    constructor(
        public id: string,
        public name: string,
        public icon: string,
        public description: string,
        public subCategory: Category[],
        public zIndex: number,
        public bounds: number[],
        public extraId?: string,
        public currentState?: string,
        public listPosition?: number,
        public group?: string,
        public numVisibleLayers?: number,
        public isDisabled?: boolean,
        public subCatId?: string
    ) {
        /*this.numVisibleLayers = 0;
        for (const entry of this.layers) {
           if (entry.isVisible) {
               this.numVisibleLayers += 1;
           }
        }
        */
    }

    addCategory(category: Category) {
        const existentCategory = this.findCategory(category.id);
        if (!existentCategory) {
            this.subCategory.unshift(category);
        }
    }

    findCategory(categoryId: string): Category {
        return this.subCategory.find((thisCategory) => thisCategory.id === categoryId);
    }

   
}
