import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class UtilitiesService {
  panelContentChangedSource = new Subject<string>();
  panelContentChanged$ = this.panelContentChangedSource.asObservable();

  hasLayerInfoBeenViewed = false;
  hasService3InfoBeenViewed = false;

  constructor() { }

}
