import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { MatSnackBar, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { DefaultUrlSerializer, UrlSegment, UrlTree } from '@angular/router';
import { Http, Response } from '@angular/http';
import { AngularFireDatabase } from 'angularfire2/database';

import { Layer } from './layer';
import { Category } from './category';
import { LayersService } from './layers.service';
import { GpSettings } from './gp-settings';
import { ChartWindowComponent } from '../chart-window/chart-window.component';
import { TooltipParameters } from '../map/map-tooltip/tooltip-parameters';
import * as xmlp from 'xml2js';
import proj4 from 'proj4'

import { SeriesChartComponent, TimeParameter } from '../series-chart/series-chart.component';

import { TimelineService } from '../shared/timeline.service';

import * as L from 'leaflet';
import 'leaflet-editable';
import 'leaflet-easyprint';
import * as esri from 'esri-leaflet';
import { HttpClient } from '@angular/common/http';
import { ParentCategory } from './parent-category';
import { GoogleAnalyticsService } from './google-analytics.service';


// declare var L: any;
declare var $: any;
declare var Materialize: any;
declare var C: any;
declare var moment: any;

@Injectable()
export class MapService {

    private static readonly animationAttempts = 5;

    public static mapLayers = new Map<string, any>();
    private layerGroups = new Map<string, any>();

    private map: any;
    private mapDiv: string;
    private wmsRequestParameters = {
        layers: '',
        format: 'image/png',
        transparent: true,
        version: '1.3.0',
        uppercase: true,
        abovemaxcolor: 'transparent',
        belowmincolor: 'transparent',
        bgcolor: 'transparent',
        crs: L.CRS.EPSG3857,
        time: '',
        zIndex: 0,
        styles: '',
        colorscalerange: '',
        tileSize: 200
    };

    private numberOfLoadingLayers = 0;
    public isLoading = false;
    public isAnimationLoading = false;
    private loadingChangedSource = new Subject<boolean>();
    private tooltipVisibilityChangedSource = new Subject<TooltipParameters>();
    public drawingChangedSource = new Subject<[boolean, number]>();
    private animationChangedSource = new Subject<boolean>();

    private animatedLayer: any;
    private animatedLayerSource: Layer;

    private circleGroup: any;
    private markerGroup: any;
    private plotMarker: any;
    private featureGroups = {};
    private transectPoly: any;

    private currentCursor = 'default';

    private isDrawing = false;
    public isPlotActive = false;
    public isLoaderActive = false;

    public isPlotOpen = false;
    public currentPlotPoint: any;

    private mapPath: any;

    private latlngCursor: any;
    public isLatlngCursorActive = false;
    public printPlug: any;


    isTimeSeriesOpen = false;
    firstProj = proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    secondProj = proj4.defs("EPSG:3857", "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs");
    loadingChanged$ = this.loadingChangedSource.asObservable();
    tooltipVisibilityChanged$ = this.tooltipVisibilityChangedSource.asObservable();
    drawingChanged$ = this.drawingChangedSource.asObservable();
    animationChanged$ = this.animationChangedSource.asObservable();

    constructor(private af: AngularFireDatabase, private layersService: LayersService, private ga: GoogleAnalyticsService,
        public snackBar: MatSnackBar, public dialog: MatDialog, private http: Http, private timeline: TimelineService) {
        layersService.currentDateChanged$.subscribe(
            () => {
                this.setNewTimeSource();
            }
        );

        layersService.currentTimeChanged$.subscribe(
            () => {
                this.setNewTimeSource();
            }
        );

        layersService.zIndexChanged$.subscribe(
            (layer) => {
                this.setLayerZIndex(layer);
            }
        );

        layersService.masterLayerChanged$.subscribe(
            (layer) => {
                if (layer !== undefined && layer.leafletSource && layer.leafletSource instanceof Object) {
                    layer.leafletSource.bringToFront();
                }
            }
        )

        layersService.layerResetChanged$.subscribe(
            (layer) => {
                if (layer !== undefined && layer.leafletSource) {
                    this.map.removeLayer(layer.leafletSource)
                }
            }
        )
        layersService.layerVisibilityChanged$.subscribe(
            (layerNew) => {
                if (layerNew !== null) {
                    this.updateTimeseriesPlot(layerNew);
                } else {
                    this.dialog.closeAll();
                }
            });
    }

    createMap(mapContainer: string) {
        this.mapDiv = mapContainer;
        const maxBounds = L.latLngBounds(
            L.latLng(10, -50), // Southwest
            L.latLng(60, 10)  // Northeast
        );
        if (this.map === undefined) {
            this.map = L.map(mapContainer, {
                editable: true,
                zoomControl: false,
                center: [36, -9],
                minZoom: 4,
                maxBounds: maxBounds,
                maxBoundsViscosity: 1.0,
            }).fitBounds(maxBounds);
            this.markerGroup = L.featureGroup();
            this.markerGroup.addTo(this.map);

            L.control.zoom({
                position: 'topright'
            }).addTo(this.map);

            L.easyPrint({
                title: 'Save image',
                position: 'topright',
                exportOnly: true,
                sizeModes: ['A4Landscape'],
                defaultSizeTitles: {A4Landscape: 'Export Map to Image File'}
            }).addTo(this.map);

        }
        this.map.on('click', (evt) => {
            if (!this.isDrawing && this.isPlotActive && !this.isDrawing && this.dialog.openDialogs.length === 0) {
                this.identify(evt);
            }
        });

        this.map.on('zoomend', (evt) => {
            if (this.mapPath !== undefined) {
                if (this.map.getZoom() > 11) {
                    this.map.addLayer(this.mapPath);
                } else {
                    this.map.removeLayer(this.mapPath)
                }
            }
        });

        this.map.on('mouseover', (evt) => {
            this.latlngCursor = evt.latlng;
        })

        this.map.on('mousemove', (evt) => {
            this.latlngCursor = evt.latlng;
        })

        // this.isLatlngCursorActive = true;

    }

    addBasemap(basemap: string) {
        if (basemap == 'oceans') {
            esri.basemapLayer('Streets', {//Oceans, Topographic
                minZoom: 2,
                maxZoom: 22,
                maxNativeZoom: 19
            }).addTo(this.map);
        }
        else {
            esri.basemapLayer('Imagery', {
                minZoom: 2,
                maxZoom: 22,
                maxNativeZoom: 19
            }).addTo(this.map);
        }
    }

    loadingHandler = () => {
        this.numberOfLoadingLayers += 1;
        this.emitLoading(true);
    };

    loadHandler = (e) => {
        this.numberOfLoadingLayers -= 1;

        if (this.numberOfLoadingLayers <= 0) {
            this.emitLoading(false);
        }
    };

    errorHandler = (v) => {
        this.snackBar.open('Error loading layer', 'CLOSE');
    }

    addTileWMSLayer(layer: Layer, featureGroup?: string) {
        let tileFailures = 0;
        const requestParams = this.wmsRequestParameters;
        requestParams.layers = layer.id;
        requestParams.zIndex = layer.zIndex;
        requestParams.version = '1.1.1';
        requestParams['bounds'] = L.latLngBounds([
            [layer.bbox[1], layer.bbox[0]],
            [layer.bbox[3], layer.bbox[2]]
        ]);
        requestParams.styles = layer.currentStyle + '/' + layer.currentPalette;
        requestParams.time = moment.utc(layer.nearestTime).format('YYYY-MM-DDTHH:mm:ss');
        //requestParams.time = moment.utc(this.layersService.getCurrentDateTime).format('YYYY-MM-DDTHH:mm:ss');
        requestParams.colorscalerange = layer.currentRange.join(',');
        layer.leafletSource = L.tileLayer.wms(GpSettings.NCWMS_URL, requestParams);
        this.wmsRequestParameters.version = '1.3.0';
        layer.leafletSource.on('loading', this.loadingHandler);
        layer.leafletSource.on('load', this.loadHandler);
        layer.leafletSource.on('tileerror', (e) => {
            tileFailures++;
            if (tileFailures <= 10) {
                //this.snackBar.open(`Reloading some tiles from ${layer.verboseId}`, 'OK', { duration: 2500 });
                const tileurl = layer.leafletSource.getTileUrl(e.coords);
                e.tile.src = tileurl + "&rnd=" + Math.random();
            } else {
                //this.snackBar.open(`Problems loading layer ${layer.verboseId}`, 'OK', { duration: 2500 });
                tileFailures = undefined;
            }
            /*setTimeout(function() {
                e.tile.src = tileurl + "&rnd="+Math.random();
            }, Math.pow(tileFailures, 2));*/
        });
        this.addLayerToMap(layer);

    }

    setLayerOpacity(layer: Layer, newValue: number) {
        layer.leafletSource.setOpacity(newValue);
    }

    setLayerZIndex(layer: Layer) {
        if (layer.leafletSource) {
            layer.leafletSource.setZIndex(layer.zIndex);
        }
    }

    setLayerVisiblity(layer: Layer) {
        let undefinedTimeSource = false;
        if (typeof (this.layersService.getCurrentDate) === 'undefined' ||
            typeof (this.layersService.getCurrentTime) === 'undefined') {
            this.layersService.setCurrentDatetime = layer.nearestTime;
            undefinedTimeSource = true;
        }

        if (!layer.leafletSource) {
            this.layersService.setCurrentDatetime = layer.nearestTime;
            this.addTileWMSLayer(layer);
            /*this.layersService.checkIfDatetimeAvailable(layer, this.layersService.getCurrentDateTime.replace(' ', 'T'),
                () => {
                    this.addTileWMSLayer(layer);
                },
                () => {
                    this.snackBar.open('Selected date/time not available for ' + layer.verboseId, 'OK');
                }
            );*/
        } else {
            if (layer.isVisible) {
                this.layersService.setCurrentDatetime = layer.nearestTime;
                this.addLayerToMap(layer);
                this.ga.trackEvent("View layer", "Layer", layer.verboseId, layer.verboseId);
            } else {
                if (layer.category.id) {
                    this.featureGroups[layer.category.id].feature.removeLayer(layer.leafletSource);
                } else {
                    this.map.removeLayer(layer.leafletSource);
                }
            }
        }
        this.layersService.emitLayerVisibilityChanged(layer);
    }

    addLayerToMap(layer: Layer) {
        if (layer.isVisible) {
            if (layer.category.id) {
                if (!this.featureGroups.hasOwnProperty(layer.category.id)) {
                    this.featureGroups[layer.category.id] = {
                        feature: L.featureGroup(),
                        bounds: L.latLngBounds([
                            [layer.bbox[1], layer.bbox[0]],
                            [layer.bbox[3], layer.bbox[2]]
                        ])
                    }
                    this.featureGroups[layer.category.id].feature.addTo(this.map);
                }

                this.featureGroups[layer.category.id].bounds.extend(L.latLngBounds([
                    [layer.bbox[1], layer.bbox[0]],
                    [layer.bbox[3], layer.bbox[2]]
                ]));
                this.featureGroups[layer.category.id].feature.addLayer(layer.leafletSource);
                if (!this.map.hasLayer(layer.leafletSource)) {
                    this.map.addLayer(layer.leafletSource);
                }

            } else {
                layer.leafletSource.addTo(this.map);
            }
        }
    }


    setColorbarRange(layer: Layer, newValues: number[]) {
        layer.currentRange = newValues;
        this.updateLayerParams(layer, 'colorscalerange',
            layer.currentRange.join(','));
    }

    setStyle(layer: Layer, newStyle: string) {
        layer.currentStyle = newStyle;
        this.updateLayerParams(layer, 'styles',
            layer.currentStyle + '/' + layer.currentPalette);
    }

    setPalette(layer: Layer, newPalette: string) {
        layer.currentPalette = newPalette;

        this.updateLayerParams(layer, 'styles',
            layer.currentStyle + '/' + layer.currentPalette);
    }

    toggleLogScale(layer: Layer, logScaleOn: boolean) {
        this.updateLayerParams(layer, 'logScale', logScaleOn);
    }

    updateLayerParams(layer: Layer, paramName: string, newVal: any) {
        if (layer.leafletSource !== null && layer.leafletSource instanceof Object) {
            const newSettings = {};
            newSettings[paramName] = newVal;
            layer.leafletSource.setParams(newSettings);
        }
    }

    /**
     * Is invoked when a new date is selected in the timeline
     */
    setNewTimeSource() {
        for (const lyr of this.layersService.getLayers) {
            if (lyr.isVisible && lyr.leafletSource !== null) {
                const wmsTimeString = this.layersService.getCurrentDateTime.replace(' ', 'T');
                this.layersService.checkIfDatetimeAvailable(lyr, wmsTimeString,
                    () => {
                        if (lyr.leafletSource) {
                            lyr.leafletSource.setParams({
                                'time': wmsTimeString
                            });
                            if (!this.map.hasLayer(lyr.leafletSource)) {
                                this.map.addLayer(lyr.leafletSource);
                            }
                        } else {
                            this.addTileWMSLayer(lyr);
                        }
                    },
                    () => {
                        if (lyr.leafletSource) {
                            if (this.map.hasLayer(lyr.leafletSource)) {
                                this.map.removeLayer(lyr.leafletSource);
                            }
                        }
                        this.snackBar.open('Selected date/time not available for ' + lyr.verboseId, 'OK');
                    });
            }
        }
    }

    /**
     * Clears all SSI markers from the map
     */
    clearSSIMarkers() {
        this.markerGroup.clearLayers();
    }

    /**
     * Remove marker layer
     */
    removeMarker(layer: Layer) {
        this.markerGroup.removeLayer(layer.marker);
        this.markerGroup.removeLayer(layer.markerDot);
    }


    /**
     * Adds a marker to the map, used to display SSI values
     * @param lat
     * @param lon
     * @param ssiCode
     * @param zoomToBounds
     * @param pinDescription
     */
    addMapMarker(lat: Number, lon: Number, ssiCode: string, zoomToBounds: boolean, layer: Layer, pinDescription?: string, productName?: string, estuaryName?: string) {
        const ssiColorCode = {
            'Invertebrates_RiaFormosa': './assets/img/macroinvertebrates_pin_blue.svg',
            'Invertebrates_RiaAveiro': './assets/img/macroinvertebrates_pin_brown.svg',
            'Invertebrates_Sado': './assets/img/macroinvertebrates_pin_cyan.svg',
            'Invertebrates_Tejo': './assets/img/macroinvertebrates_pin_darkblue.svg',
            'Invertebrates_CentralCoast': './assets/img/macroinvertebrates_pin_darkpink.svg',
            'Invertebrates_AlgarveCoast': './assets/img/macroinvertebrates_pin_gray.svg',
            'Invertebrates_NorthCoast': './assets/img/macroinvertebrates_pin_green.svg',
            'Invertebrates_South-westCoast': './assets/img/macroinvertebrates_pin_orange.svg',
            'Invertebrates_Douro': './assets/img/macroinvertebrates_pin_pinky.svg',
            'Invertebrates_Guadiana': './assets/img/macroinvertebrates_pin_purple.svg',
            'Invertebrates_LagoaObidos': './assets/img/macroinvertebrates_pin_red.svg',
            'Invertebrates_Minho': './assets/img/macroinvertebrates_pin_tealblue.svg',
            'Invertebrates_Mira': './assets/img/macroinvertebrates_pin_wine.svg',
            'Invertebrates_Mondego': './assets/img/macroinvertebrates_pin_yellow.svg',

            'MacroAlgae_RiaFormosa': './assets/img/macroalgae_pin_blue.svg',
            'MacroAlgae_RiaAveiro': './assets/img/macroalgae_pin_brown.svg',
            'MacroAlgae_Sado': './assets/img/macroalgae_pin_cyan.svg',
            'MacroAlgae_Tejo': './assets/img/macroalgae_pin_darkblue.svg',
            'MacroAlgae_CentralCoast': './assets/img/macroalgae_pin_darkpink.svg',
            'MacroAlgae_AlgarveCoast': './assets/img/macroalgae_pin_gray.svg',
            'MacroAlgae_NorthCoast': './assets/img/macroalgae_pin_green.svg',
            'MacroAlgae_South-westCoast': './assets/img/macroalgae_pin_orange.svg',
            'MacroAlgae_Douro': './assets/img/macroalgae_pin_pinky.svg',
            'MacroAlgae_Guadiana': './assets/img/macroalgae_pin_purple.svg',
            'MacroAlgae_LagoaObidos': './assets/img/macroalgae_pin_red.svg',
            'MacroAlgae_Minho': './assets/img/macroalgae_pin_tealblue.svg',
            'MacroAlgae_Mira': './assets/img/macroalgae_pin_wine.svg',
            'MacroAlgae_Mondego': './assets/img/macroalgae_pin_yellow.svg',

            'Phytoplankton_RiaFormosa': './assets/img/phytoplankton_pin_blue.svg',
            'Phytoplankton_RiaAveiro': './assets/img/phytoplankton_pin_brown.svg',
            'Phytoplankton_Sado': './assets/img/phytoplankton_pin_cyan.svg',
            'Phytoplankton_Tejo': './assets/img/phytoplankton_pin_darkblue.svg',
            'Phytoplankton_CentralCoast': './assets/img/phytoplankton_pin_darkpink.svg',
            'Phytoplankton_AlgarveCoast': './assets/img/phytoplankton_pin_gray.svg',
            'Phytoplankton_NorthCoast': './assets/img/phytoplankton_pin_green.svg',
            'Phytoplankton_South-westCoast': './assets/img/phytoplankton_pin_orange.svg',
            'Phytoplankton_Douro': './assets/img/phytoplankton_pin_pinky.svg',
            'Phytoplankton_Guadiana': './assets/img/phytoplankton_pin_purple.svg',
            'Phytoplankton_LagoaObidos': './assets/img/phytoplankton_pin_red.svg',
            'Phytoplankton_Minho': './assets/img/phytoplankton_pin_tealblue.svg',
            'Phytoplankton_Mira': './assets/img/phytoplankton_pin_wine.svg',
            'Phytoplankton_Mondego': './assets/img/phytoplankton_pin_yellow.svg',

            'Saltmarshes_RiaFormosa': './assets/img/marshes_pin_blue.svg',
            'Saltmarshes_RiaAveiro': './assets/img/marshes_pin_brown.svg',
            'Saltmarshes_Sado': './assets/img/marshes_pin_cyan.svg',
            'Saltmarshes_Tejo': './assets/img/marshes_pin_darkblue.svg',
            'Saltmarshes_CentralCoast': './assets/img/marshes_pin_darkpink.svg',
            'Saltmarshes_AlgarveCoast': './assets/img/marshes_pin_gray.svg',
            'Saltmarshes_NorthCoast': './assets/img/marshes_pin_green.svg',
            'Saltmarshes_South-westCoast': './assets/img/marshes_pin_orange.svg',
            'Saltmarshes_Douro': './assets/img/marshes_pin_pinky.svg',
            'Saltmarshes_Guadiana': './assets/img/marshes_pin_purple.svg',
            'Saltmarshes_LagoaObidos': './assets/img/marshes_pin_red.svg',
            'Saltmarshes_Minho': './assets/img/marshes_pin_tealblue.svg',
            'Saltmarshes_Mira': './assets/img/marshes_pin_wine.svg',
            'Saltmarshes_Mondego': './assets/img/marshes_pin_yellow.svg',

            'Fishes_RiaFormosa': './assets/img/fish_pin_blue.svg',
            'Fishes_RiaAveiro': './assets/img/fish_pin_brown.svg',
            'Fishes_Sado': './assets/img/fish_pin_cyan.svg',
            'Fishes_Tejo': './assets/img/fish_pin_darkblue.svg',
            'Fishes_CentralCoast': './assets/img/fish_pin_darkpink.svg',
            'Fishes_AlgarveCoast': './assets/img/fish_pin_gray.svg',
            'Fishes_NorthCoast': './assets/img/fish_pin_green.svg',
            'Fishes_South-westCoast': './assets/img/fish_pin_orange.svg',
            'Fishes_Douro': './assets/img/fish_pin_pinky.svg',
            'Fishes_Guadiana': './assets/img/fish_pin_purple.svg',
            'Fishes_LagoaObidos': './assets/img/fish_pin_red.svg',
            'Fishes_Minho': './assets/img/fish_pin_tealblue.svg',
            'Fishes_Mira': './assets/img/fish_pin_wine.svg',
            'Fishes_Mondego': './assets/img/fish_pin_yellow.svg',
            '1': './assets/img/harbour_pin_green_2.svg',
            'shadow': './assets/img/harbour_pin_shadow.svg'
        };
        let ssiIcon;
        if (productName && estuaryName) {
            ssiIcon = L.icon({
                iconUrl: ssiColorCode[productName + "_" + estuaryName],
                shadowUrl: ssiColorCode.shadow,
                iconSize: [36, 50], // size of the icon
                shadowSize: [40, 20], // size of the shadow
                iconAnchor: [13, 40], // point of the icon which will correspond to marker's location
                shadowAnchor: [13, 20],  // the same for the shadow
                popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
            });
        } else {
            ssiIcon = L.icon({
                iconUrl: ssiColorCode[ssiCode],
                shadowUrl: ssiColorCode.shadow,
                iconSize: [36, 50], // size of the icon
                shadowSize: [40, 20], // size of the shadow
                iconAnchor: [13, 40], // point of the icon which will correspond to marker's location
                shadowAnchor: [13, 20],  // the same for the shadow
                popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
            });
        }

        // ----- TEMPORARY UNTIL COORDS ARE FIXED BY PAOLA/HUGO -----
        const temporary_coords = {
            'tejo_buoy1': [38.693738, -9.233278],
            'tejo_buoy2': [38.762358, -9.086870],
            'tejo_buoy3': [38.876552, -9.020972]
        }
        let marker;
        let coords;
        if (layer.category) {
            coords = temporary_coords[layer.category.id];
        }

        if (!coords) {
            marker = L.marker([lat, lon], { icon: ssiIcon });
        } else {
            marker = L.marker(coords, { icon: ssiIcon });
        }
        // ------------------------------------------------------------

        if (pinDescription) {
            marker.bindPopup(pinDescription);
        }
        if (!layer.isDatasetLayer) {
            marker.on('click', (e) => {
                this.layersService.setMasterLayer = layer;
                this.showChart(e.layerPoint);

            });
        }
        layer.marker = marker;
        this.markerGroup.addLayer(marker);
        if (zoomToBounds) {
            this.map.fitBounds(this.markerGroup.getBounds());
        }
    }

    addMapCircle(lat: Number, lon: Number, radius: Number, zoomToBounds: boolean, layer: Layer, pinDescription?: string) {
        const circleMarker = L.circle([lat, lon], { radius: radius });
        // const dot = L.circleMarker([lat, lon], { radius: 1 });
        if (pinDescription) {
            circleMarker.bindPopup(pinDescription);
        }
        layer.marker = circleMarker;
        // layer.markerDot = dot;
        this.markerGroup.addLayer(circleMarker);
        // this.markerGroup.addLayer(dot);
        if (zoomToBounds) {
            this.map.fitBounds(this.markerGroup.getBounds());
        }
    }

    addMapPath(coordList: any[]) {
        if (this.mapPath === undefined) {
            this.mapPath = L.layerGroup();
        }

        const ssiIcon = L.icon({
            iconUrl: '../../assets/img/harbour_pin_indigo.svg',
            shadowUrl: '../../assets/img/harbour_pin_shadow.svg',
            iconSize: [10, 24], // size of the icon
            shadowSize: [24, 4], // size of the shadow
            iconAnchor: [5, 24], // point of the icon which will correspond to marker's location
            shadowAnchor: [5, 12],  // the same for the shadow
            popupAnchor: [0, -24] // point from which the popup should open relative to the iconAnchor
        });
        let marker = L.marker([coordList[0].lat, coordList[0].lon], { icon: ssiIcon });
        this.mapPath.addLayer(marker);
        marker = L.marker([coordList[1].lat, coordList[1].lon], { icon: ssiIcon });
        this.mapPath.addLayer(marker);
        marker = L.marker([coordList[2].lat, coordList[2].lon], { icon: ssiIcon });
        this.mapPath.addLayer(marker);
        marker = L.marker([coordList[3].lat, coordList[3].lon], { icon: ssiIcon });
        this.mapPath.addLayer(marker);
        marker = L.marker([coordList[4].lat, coordList[4].lon], { icon: ssiIcon });
        this.mapPath.addLayer(marker);

        const polyPath = new L.Polyline.AntPath([[coordList[0].lat, coordList[0].lon],
        [coordList[1].lat, coordList[1].lon],
        [coordList[2].lat, coordList[2].lon],
        [coordList[3].lat, coordList[3].lon],
        [coordList[4].lat, coordList[4].lon]], {
                color: '#000000',
                pulseColor: '#FFFFFF',
                delay: 1000
            });
        this.mapPath.addLayer(polyPath);
        if (this.map.getZoom() > 11) {
            this.map.addLayer(this.mapPath);
        }
    }

    clearMapPath() {
        if (this.mapPath !== undefined) {
            this.map.removeLayer(this.mapPath);
            this.mapPath = undefined;
        }
    }

    setZoomLevel(zoom: Number) {
        this.map.setZoom(zoom);
    }

    createSubgroup(layers: Layer[]) {
        const newGroup = L.featureGroup();
        for (const lyr of layers) {
            if (lyr.marker !== null) {
                newGroup.addLayer(lyr.marker)
            }
        }
        return newGroup;
    }

    zoomToMarkers(category: ParentCategory) {
        if (this.markerGroup.getLayers().length > 1) {
            let catMarkers;
            const datasetMarkers = this.layersService.getVisibleLayers.filter(lyr => lyr.isDatasetLayer);
            const otherMarkers = this.layersService.getVisibleLayers.filter(lyr => !lyr.isDatasetLayer);
            if (otherMarkers.length > 0) {
                catMarkers = otherMarkers.filter(lyr => lyr.category.parentCategory === category)
                if (catMarkers.length === 0 && datasetMarkers.length > 0) {
                    catMarkers = datasetMarkers.filter(lyr => lyr.id.split('_')[1] === category.name.replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                }
            } else {
                catMarkers = datasetMarkers.filter(lyr => lyr.id.split('_')[1] === category.name.replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
            }
            if (catMarkers.length > 0) {
                const tempGroup = this.createSubgroup(catMarkers)
                this.map.fitBounds(tempGroup.getBounds());
            }
        } else if (this.markerGroup.getLayers().length == 1) {
            this.setZoomLevel(14);
            this.map.fitBounds(this.markerGroup.getBounds());
        }
    }

    zoomToMarker(bounds: Number[]) {
        if (bounds !== undefined) {
            this.map.fitBounds([
                [bounds[0], bounds[1]],
                [bounds[2], bounds[3]]
            ]);
            this.setZoomLevel(14);
        }
    }

    zoomToFeature(featureId: string) {
        if (this.featureGroups.hasOwnProperty(featureId)) {
            this.map.fitBounds(this.featureGroups[featureId].bounds);
        }
    }

    zoomToBounds(bounds: number[]) {
        this.map.fitBounds([
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]]
        ])
    }

    emitLoading(status: boolean) {
        if (!status && this.isAnimationLoading) {
            status = true;
        }
        this.loadingChangedSource.next(status);
    }

    addAnimatedLayer(layer: Layer, timeInterval: string, frameRate: string, attempts?: number) {
        this.animatedLayerSource = layer;
        this.map.removeLayer(layer.leafletSource);
        const bbox = [[layer.bbox[1], layer.bbox[0]], [layer.bbox[3], layer.bbox[2]]];
        let bboxString
        if (layer.id.lastIndexOf('nrt', 0) === 0 || layer.id.lastIndexOf('chl_ocean', 0) === 0) {
            bboxString = layer.bbox[0] + ',' + layer.bbox[3] + ',' + layer.bbox[2] + ',' + layer.bbox[1];
        } else {
            bboxString = layer.bbox[0] + ',' + layer.bbox[1] + ',' + layer.bbox[2] + ',' + layer.bbox[3];
        }
        const params = ['service=WMS', 'request=GetMap', 'version=1.3.0', 'format=image/gif',
            'transparent=true', 'styles=' + layer.currentStyle + '/' + layer.currentPalette,
            'layers=' + layer.id, 'time=' + timeInterval,
            'bbox=' + bboxString, 'width=1024', 'height=1024', 'animation=true', 'CRS=CRS:84',
            'colorscalerange=' + layer.currentRange[0] + ',' + layer.currentRange[1],
            'frameRate=' + frameRate, 'ABOVEMAXCOLOR=transparent', 'BELOWMINCOLOR=transparent', 'BGCOLOR=transparent']

        const imgUrl = GpSettings.NCWMS_URL + '?' + params.join('&');
        if (this.numberOfLoadingLayers === 0) {
            this.isAnimationLoading = true;
            this.emitLoading(true);
        }
        this.animatedLayer = L.imageOverlay(imgUrl, bbox, { opacity: layer.opacity });
        this.animatedLayer.on('error', () => {
            attempts++;
            if (attempts > MapService.animationAttempts) {
                this.snackBar.open(`Error loading Animation, please try different dates.`, 'OK');
                this.isAnimationLoading = false;
                this.emitLoading(false);
                return 'error'
            }
            this.addAnimatedLayer(layer, timeInterval, frameRate, attempts);
        })
        this.animatedLayer.on('load', () => {
            this.isAnimationLoading = false;
            this.emitLoading(false);
        })
        this.animatedLayer.addTo(this.map);
    }

    removeAnimatedLayer() {
        this.animatedLayer.remove();
        this.map.addLayer(this.animatedLayerSource.leafletSource);
        this.animatedLayer = undefined;
    }

    clearMap(basemap: string) {
        this.map.eachLayer((layer) => {
            this.map.removeLayer(layer);
        });
        this.featureGroups = {};
        this.mapPath = undefined;
        this.map.remove();
        this.map = undefined;
        this.isAnimationLoading = false;
        this.animationChangedSource.next(false);
        this.createMap(this.mapDiv);
        this.addBasemap(basemap);
    }

    clearLayers() {
        this.featureGroups = {};
    }

    drawTransect() {
        let wasTriggered = false;
        this.isDrawing = true;
        this.drawingChangedSource.next([true, 0]);
        if (this.transectPoly) {
            this.map.removeLayer(this.transectPoly.layer);
        }
        this.map.editTools.stopDrawing();
        this.map.editTools.startPolyline(undefined, {
            color: '#ff6d00',
            lineJoin: 'round'
        });

        this.map.on('editable:drawing:end', (poly) => {
            this.map.editTools.stopDrawing();
            this.isDrawing = false;
            this.drawingChangedSource.next([false, 0]);

            this.transectPoly = poly;
            if (!wasTriggered) {
                this.getTransectPlot(poly.layer._latlngs);
            }
            wasTriggered = true;
        });
        this.map.on('editable:vertex:commit', (poly) => {
            this.map.editTools.stopDrawing();
            this.isDrawing = false;
            this.drawingChangedSource.next([false, 0]);

            this.transectPoly = poly;
            if (wasTriggered) {
                this.getTransectPlot(poly.layer._latlngs);
            }
            wasTriggered = true;
        });
    }

    getTransectPlot(latLngArray: any[]) {

        if (this.layersService.getMasterLayer) {
            let lineString = '';
            for (const latLng of latLngArray) {
                lineString += latLng.lng + ' ' + latLng.lat + ',';
            }
            const transectRequestParams = {
                REQUEST: 'GetTransect',
                CRS: 'CRS:84',
                LINESTRING: lineString,
                LAYERS: this.layersService.getMasterLayer.id,
                FORMAT: 'image/png',
                TIME: this.layersService.getCurrentDateTime.replace(' ', 'T'),
                COLORSCALERANGE: this.layersService.getMasterLayer.currentRange.join(','),
                NUMCOLORBANDS: '250',
                LOGSCALE: 'false',
                ABOVEMAXCOLOR: 'transparent',
                BELOWMINCOLOR: 'transparent',
                BGCOLOR: 'transparent',
                PALETTE: this.layersService.getMasterLayer.currentPalette
            }
            const serializer = new DefaultUrlSerializer();
            const simpleUrl = serializer.parse('');
            simpleUrl.queryParams = transectRequestParams;

            this.openDialog(this.layersService.getMasterLayer.verboseId + ' Transect Plot',
                true, GpSettings.NCWMS_URL + serializer.serialize(simpleUrl));
        }

    }

    openDialog(title: string, isTransect: boolean, transectUrl?: string) {
        const dialog = this.dialog.open(ChartWindowComponent, {
            width: '600px'
        });

        dialog.componentInstance.chartTitle = title;
        dialog.componentInstance.isTransect = isTransect;
        dialog.componentInstance.transectUrl = transectUrl;
    }

    // The following functions are "inspired" by leaflet.wms.js
    // (see: https://github.com/heigeo/leaflet.wms/blob/gh-pages/src/leaflet.wms.js#L139)

    /* Identify map features in response to map clicks. To customize this
    behavior, create a class extending wms.Source and override one or
    more of the following hook functions.*/
    identify(evt): void {
        const visibleLayers = this.layersService.getVisibleLayers;
        const layers = visibleLayers.filter(lyr => lyr.leafletSource instanceof Object)
        if (this.plotMarker !== undefined) {
            this.map.removeLayer(this.plotMarker);
            this.plotMarker = undefined;
        }

        if (layers && layers.length > 0) {
            const requests: Observable<string>[] = layers.map((layer) => {
                const params = this.featureInfoParams(evt.containerPoint, layer);
                const url = GpSettings.NCWMS_URL + L.Util.getParamString(params, GpSettings.NCWMS_URL);
                return this.http.get(url).map(result => result.text());
            });
            this.showWaitingCursor();

            Observable.forkJoin(requests).subscribe(
                (results: string[]) => {
                    const tooltipParams = new TooltipParameters(
                        true, undefined, (evt.containerPoint.y - 40) + 'px',
                        evt.containerPoint.x + 'px', '', '', [], evt.containerPoint);
                    results.forEach(result => {
                        xmlp.parseString(result, (err, parsedResult) => {
                            tooltipParams.lat = parsedResult.FeatureInfoResponse.latitude[0];
                            tooltipParams.lng = parsedResult.FeatureInfoResponse.longitude[0];
                            if (this.plotMarker === undefined) {
                                this.plotMarker = L.marker([tooltipParams.lat, tooltipParams.lng]).addTo(this.map);
                            }
                            try {
                                tooltipParams.time = new Date(parsedResult.FeatureInfoResponse.Feature[0].FeatureInfo[0].time[0]);
                                tooltipParams.infoData.push({
                                    'layer': parsedResult.FeatureInfoResponse.Feature[0].layer[0],
                                    'value': parsedResult.FeatureInfoResponse.Feature[0].FeatureInfo[0].value[0]
                                });
                            } catch (TypeError) {
                                this.snackBar.open('Pixel selection outside visible layers area', 'OK');
                            }
                        });
                    });
                    this.tooltipVisibilityChangedSource.next(tooltipParams);
                    this.showDefaultCursor();
                }
            );
        }
    };

    public removePlotMarker() {
        if (this.plotMarker !== undefined) {
            this.map.removeLayer(this.plotMarker);
            this.plotMarker = undefined;
        }
    }

    public showChart(point: any): void {
        this.timeline.timeSeriesActivatedSource.next(true)
        this.isTimeSeriesOpen = true;
        this.currentPlotPoint = point;
        //const layers = this.layersService.getVisibleLayers.map(layer => { return layer; });
        const dialog = this.dialog.open(SeriesChartComponent, {
            height: '60%',
            width: '65%',
            position: {
                right: '10%',
                left: '26%',
                bottom: '16%',
                top: '3%'
            }
        });
        this.isPlotOpen = true;
        //this.timeline.timeSeriesActivatedSource.next(true);
        dialog.afterClosed().subscribe(result => {
            this.isPlotOpen = false;
            this.timeline.timeSeriesActivatedSource.next(false);
        });
        const serieschart: SeriesChartComponent = dialog.componentInstance;
        const drawChart = (start: Date, end: Date) => {
            const layers = this.layersService.getVisibleLayers.map(layer => { return layer; });
            serieschart.chartLoading = true;
            serieschart.clear();
            this.getSeries(point, layers, start, end).subscribe(([layer, series]) => {
                if (!serieschart.hasParameter(layer.id)) {
                    let varName;
                    if (layer.category !== undefined && layer.category.parentCategory !== undefined) {
                        if (layer.isDatasetLayer) {
                          varName = layer.category.parentCategory.name + ' - ' + layer.category.name;
                        } else {
                          varName = layer.category.parentCategory.name + ' - ' + layer.category.name + ' - ' + layer.verboseId;
                        }
                      } else {
                        varName = layer.verboseId;
                      }
                    serieschart.addParameter(varName, layer.id, layer.units, series);
                    this.ga.trackEvent("View plot", "Plot", layer.verboseId, "")
                }
            },
                () => { },
                () => {
                    serieschart.chartLoading = false;
                    serieschart.draw();
                }
            );
        };
        if (this.timeline.start && this.timeline.end) {
            this.layersService.setCurrentDatetime = this.layersService.getMasterLayer.nearestTime;
            drawChart(this.timeline.start, this.timeline.end);
        }

        this.timeline.rangeChanged$.subscribe(([start, end]) => {
            if (this.isPlotOpen) {
                drawChart(start, end);
            }
        });

        dialog.afterClosed().subscribe(() => {
            this.isTimeSeriesOpen = false;
            serieschart.clear();
        });
    }

    private featureInfosMsg(featureInfos: string[]): string {
        const lat = this.parseField('Latitude', featureInfos[0]);
        const lon = this.parseField('Longitude', featureInfos[0]);
        return `(${lat}, ${lon}): <br>` + featureInfos.map(i => {
            const layer = this.parseField('Layer', i);
            const value = this.parseField('Value', i);
            return layer && value && `${layer}: ${value}` || null;
        }).
            filter(i => { return i; }).
            join('<br>');
    }

    private parseField(field: string, wmsFeatureInfo: string): string {
        const match = wmsFeatureInfo.match(field + ':\s*\(.*\)');
        return match && match.length > 1 && match[1] || null;
    }

    /*
     * Generates parameters for WMS service GetFeatureInfo request
     */
    private featureInfoParams(point, layer: Layer): any {
        // Compute WMS options
        return this.getPointParams(point, {
            request: 'GetFeatureInfo',
            layers: layer.id,
            time: layer.nearestTime,
            styles: layer.currentStyle,
            info_format: 'text/xml',
        }, false);
    };

    private getPointParams(point: any, opts: any, includeBBox?: boolean): any {
        const size = this.map.getSize();
        if (includeBBox) {
            return L.extend({}, this.wmsRequestParameters, opts, {
                'crs': this.map.options.crs.code,
                'width': size.x,
                'height': size.y,
                'bbox': opts.bbox,
                'query_layers': opts.layers,
                'i': Math.round(point.x),
                'j': Math.round(point.y),
            });
        } else {
            return L.extend({}, this.wmsRequestParameters, opts, {
                'crs': this.map.options.crs.code,
                'width': size.x,
                'height': size.y,
                'bbox': this.bbox(),
                'query_layers': opts.layers,
                'i': Math.round(point.x),
                'j': Math.round(point.y),
            });
        }
    }

    private currentLayerBbox(layer: Layer): string {
        const wmsVersion = parseFloat(this.wmsRequestParameters['version']);
        const crs = this.map.options.crs;
        /*const bounds = this.map.getBounds();
        const nw = crs.project(bounds.getNorthWest());
        const se = crs.project(bounds.getSouthEast());*/
        //const normalizeCoords = this.map.project()
        const secProj1 = proj4('EPSG:4326', 'EPSG:3857', [layer.bbox[0], layer.bbox[1]]);
        const secProj2 = proj4('EPSG:4326', 'EPSG:3857', [layer.bbox[2], layer.bbox[3]]);
        return (
            wmsVersion >= 1.3 && crs === L.CRS.EPSG4326 ?
                [layer.bbox[1], layer.bbox[2], layer.bbox[3], layer.bbox[0]] :
                [secProj1[0], secProj1[1], secProj2[0], secProj2[1]]
            // [layer.bbox[0], layer.bbox[1], layer.bbox[2], layer.bbox[3]] after converting to EPSG3857
        ).join(',');

        //[-976366.5558, 4525476.6110, -965234.6067, 4539539.6625]

        /*return (
            wmsVersion >= 1.3 && crs === L.CRS.EPSG4326 ?
                [se.y, nw.x, nw.y, se.x] :
                [minLon, minLat, maxLon, maxLat]
        ).join(',');*/
    }

    private bbox(): string {
        const wmsVersion = parseFloat(this.wmsRequestParameters['version']);
        const crs = this.map.options.crs;
        const bounds = this.map.getBounds();
        const nw = crs.project(bounds.getNorthWest());
        const se = crs.project(bounds.getSouthEast());
        return (
            wmsVersion >= 1.3 && crs === L.CRS.EPSG4326 ?
                [se.y, nw.x, nw.y, se.x] :
                [nw.x, se.y, se.x, nw.y]
        ).join(',');
    }

    public getSeries(point: any, layers: Layer[], start: Date, end: Date): Observable<any> {
        return Observable.merge(...layers.map(layer => {
            let params
            if (layer.leafletSource instanceof Object) {
                params = this.getPointParams(point, {
                    request: 'GetTimeseries',
                    time: `${start.toISOString()}/${end.toISOString()}`,
                    layers: layer.id,
                    info_format: 'text/csv',
                    bbox: this.bbox()
                }, true);
            } else {
                params = this.getPointParams(point, {
                    request: 'GetTimeseries',
                    time: `${start.toISOString()}/${end.toISOString()}`,
                    layers: layer.id,
                    info_format: 'text/csv',
                    bbox: this.currentLayerBbox(layer)
                }, true);
            }
            const url = GpSettings.NCWMS_URL + L.Util.getParamString(params, GpSettings.NCWMS_URL);
            try {
                return this.http.get(url).map(response => {
                    const table: string[][] = this.parseCsv(response.text());
                    const series: [Date, number][] = table.slice(1).map(row => {
                        const date: Date = new Date(Date.parse(row[0]));
                        const value: number = parseFloat(row[1]);
                        const result: [Date, number] = [date, value];
                        return result;
                    });
                    return [layer, series];
                })
            } catch (Error) {
                console.log('err');
            }
        }));
    }

    private parseCsv(csv: string): string[][] {
        return csv.split(/\r?\n/).filter(row => {
            return !row.startsWith('#');
        }).map(row => {
            return row.split(',');
        });
    }

    public setCurrentcursor(cursorValue: string) {
        this.currentCursor = cursorValue;
    }
    private showWaitingCursor(): void {
        this.map._container.style.cursor = 'progress';
    }

    public showCrosshairCursor(): void {
        this.map._container.style.cursor = 'crosshair';
    }

    public showDefaultCursor(): void {
        this.map._container.style.cursor = this.currentCursor;
    }


    /**
     * Adds a rectangle polygon to the map with given coordinates.
     */
    addPolygonLayer(layerId: string, coordinates: any, isEditable?: boolean, color?: string) {
        this.removeLayerFromMap(layerId);
        const polygonLayer = L.polygon(coordinates, {
            color: color || 'red'
        }).addTo(this.map);
        MapService.mapLayers.set(layerId, polygonLayer);
        if (isEditable === undefined || isEditable === true) {
            polygonLayer.enableEdit();
        }
        return polygonLayer;
    }

    /**
     * Removes a layer with a given ID
     * If a groupId is given, the layer is removed from that group
     */
    removeLayerFromMap(layerId: string, groupId?: string) {
        if (groupId !== undefined) {
            this.removeLayerFromGroup(layerId, groupId);
        } else if (MapService.mapLayers.get(layerId) !== undefined) {
            this.map.removeLayer(MapService.mapLayers.get(layerId));
        }
    }

    /**
     * Removes a layer from a specific group.
     * This does not remove the layer from the map. For this it is recommended to use the removeLayerFromMap method.
     */
    removeLayerFromGroup(layerId: string, groupId: string) {
        this.layerGroups.get(groupId).eachLayer(lyr => {
            if (lyr.feature.properties.layerId === layerId) {
                this.layerGroups.get(groupId).removeLayer(lyr);
            }
        });
    }

    removeLeafletLayerFromMap(layer: any) {
        this.map.removeLayer(layer);
    }

    addDrawEvent(eventIdentifier: string, callbackFunction: any) {
        this.map.off(eventIdentifier, undefined);
        this.map.on(eventIdentifier, callbackFunction);
    }

    commitDrawing() {
        this.map.editTools.commitDrawing();
    }

    drawBox(boxColor?: string) {
        if (boxColor === undefined) {
            boxColor = '#4caf50';
        }
        this.map.editTools.startRectangle(undefined, {
            color: boxColor
        });
    }

    setDrawing(flag: boolean) {
        this.isDrawing = flag;
    }

    get timeSeriesOpen() {
        return this.isTimeSeriesOpen;
    }

    get latlngOfMap() {
        return this.latlngCursor;
    }

    get drawingActive() {
        return this.isDrawing;
    }

    saveMapImage() {
        this.printPlug.printMap('A4Landscape', 'map_screenshot');
    }

    updateTimeseriesPlot(layerNew: Layer) {
        if (this.dialog.openDialogs.length > 0) {
            for (const dial of this.dialog.openDialogs) {
                if (dial.componentInstance instanceof SeriesChartComponent) {
                    let layers = this.layersService.getVisibleLayers.map(layerUpdated => { return layerUpdated; });
                    if (layers.length === 0) {
                        dial.close();
                    } else {
                        if (!layerNew.isVisible) {
                            dial.componentInstance.clear();
                        } else {
                            layers = [layerNew];
                        }
                        this.getSeries(this.currentPlotPoint, layers,
                            this.timeline.start, this.timeline.end).subscribe(([layer, series]) => {
                                if (!dial.componentInstance.hasParameter(layer.id)) {
                                    let varName;
                                    if (layer.category !== undefined && layer.category.parentCategory !== undefined) {
                                        if (layer.isDatasetLayer) {
                                          varName = layer.category.parentCategory.name + ' - ' + layer.category.name;
                                        } else {
                                          varName = layer.category.parentCategory.name + ' - ' + layer.category.name + ' - ' + layer.verboseId;
                                        }
                                      } else {
                                        varName = layer.verboseId;
                                      }
                                    dial.componentInstance.addParameter(varName, layer.id, layer.units, series);
                                }
                            },
                                () => { },
                                () => {
                                    dial.componentInstance.chartLoading = false;
                                    dial.componentInstance.draw();
                                });
                    }
                }
            }
        }
    }
}
