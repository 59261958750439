import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, EventEmitter } from '@angular/core';
import { MapService, Category, Layer, CategoriesService, LayersService, UtilitiesService, TimelineService } from '../shared';
import { LayersListComponent } from '../layers-list/layers-list.component';
import { QuickInfoBoxComponent } from '../quick-info-box/quick-info-box.component';
import { MatDialog } from '@angular/material';
import { MatTabChangeEvent, VERSION } from '@angular/material';


declare const moment: any;
@Component({
  selector: 'gp-layer-container',
  templateUrl: './layer-container.component.html',
  styleUrls: ['./layer-container.component.css']
})
export class LayerContainerComponent implements OnInit, AfterViewInit {
  @Input() currentViewType: string;
  @ViewChild('layertabs') layertabs;
  @Output() closeContainer = new EventEmitter();
  satelliteTypes = ['Near Real Time', 'Time Series']
  selectedSatelliteType = this.satelliteTypes[0];
  selectedTab = {
    name: 'none',
    svgIcon: 'none',
    mdIcon: '',
    description: '<p>None</p>'
  }
  tempCategory: Category;
  // selectedTab = {
  //   name: 'forecast',
  //   svgIcon: 'forecast',
  //   mdIcon: '',
  //   description: '<p class="white-text mb-40px">Provides data derived from models for forecast activities (e.g. ECMWF, AROME).</p>'
  // };
  isLoading = false;


  visualizationMeta = {
    layers: {
      name: 'Layers',
      svgIcon: '',
      mdIcon: 'layers'
    },
    ssi: {
      name: 'Sea State Index Service (SSI)',
      svgIcon: 'harbour',
      mdIcon: '',
      description: `<p class="white-text mb-40px">Provides past and real-time sea conditions forecasts for the vicinity of
        harbour areas. This information is provided through an index (Sea State Index – SSI) that uses historical port
        access data and wave and wind characteristics model forecasts as well as sea floor depth information to
        forecast the conditions for the next hours (up to 48h). Currently the service covers four ports in
        northern Portugal: Viana do Castelo, Póvoa do Varzim, Aveiro, Figueira da Foz.<br/>

        When entering this service you can see the latest SSI forecast for each harbour and for the hour closest
        to the time of visualization. Additionally, the user can choose the visualization of the layers that were used to
        calculate the index. That index is translated into different scales, according to the harbour:

        <ul class="description-list">
          <li> Viana do Castelo </li>
          <ul>
              <li class="open"> Open </li>
              <li class="closed-1"> Closed to ships with a length less than 12 meters </li>
              <li class="closed-2"> Closed to ships with a length less than 30 meters </li>
              <li class="closed"> Closed </li>
          </ul>
          <li> Póvoa do Varzim </li>
          <ul>
              <li  class="open"> Open </li>
              <li class="closed-1"> Conditioned </li>
              <li class="closed"> Closed </li>
          </ul>
          <li> Aveiro </li>
          <ul>
              <li class="open"> Open </li>
              <li class="closed-1"> Closed to ships with a length less than 15 meters </li>
              <li class="closed-2"> Closed to ships with a length less than 35 meters </li>
              <li class="closed"> Closed </li>
          </ul>
          <li> Figueira da Foz </li>
          <ul>
              <li class="open"> Open </li>
              <li class="closed-1"> Closed to ships with a length less than 11 meters </li>
              <li class="closed-2"> Closed to ships with a length less than 35 meters </li>
              <li class="closed"> Closed </li>
          </ul>
          </p>
        `
    },
    pfa: {
      name: 'Fishing Areas Characterization',
      svgIcon: 'fishing',
      mdIcon: '',
      description: `<p class="white-text mb-40px">Potential fishing areas (PFA) are daily computed from
      satellite-derived ocean parameters that are correlated with fish distribution, namely the sea surface temperature
      (SST), and chlorophyll-a (Chla) concentration, a phytoplankton biomass proxy.
      The service is currently available for sardine and mackerel along the south and southwest part of the Portuguese
      coast, reflecting the different environmental conditions associated with the regional catch distribution for each
      species.  <br/>
      The daily PFA maps are provided as a raster layer where the pixels either assume a value of 0 or 1, where the
      areas with higher potential re represented  with a value of 1. <br />
      When the user accesses the service, and selects the“Potential Fishing Areas”layer, the most recent map is
      displayed by default. The maps for past days can be selected using the timeline. The user can also browse the
      layers that were used to calculate the PFAs.</p>`
    },
    dmof: {
      name: 'Diagnostic of Meteo-Oceanographic Fields',
      svgIcon: 'fishing',
      mdIcon: '',
      description: `<p class="white-text mb-40px">It is possible to visualize and compare different forecasts
      of the same sea state variable for a different number of variables: ocean surface current speed and direction,
      significant wave height as well as ocean surface wind speed and direction, mean sea level pressure. This service
      is available for the Atlantic Ocean coastline from Portugal mainland and it’s directed at maritime and port
      authorities, rescue teams, vessel captains as well as other users interested in assessing the range and
      uncertainty associated with the forecast of different oceanographic parameters.</p>`
    },
    forecast: {
      name: 'Forecast',
      svgIcon: 'forecast',
      mdIcon: '',
      description: '<p class="white-text mb-40px">Provides data derived from models for forecast activities (e.g. ECMWF, AROME).</p>'
    },
    insitu: {
      name: 'In-Situ',
      svgIcon: 'buoy',
      mdIcon: '',
      description: '<p class="white-text mb-40px">Provides in-situ data, such as collected from buoys.</p>'
    },
    satellite: {
      name: 'Satellite',
      svgIcon: 'satellite',
      mdIcon: '',
      description: '<p class="white-text mb-40px">Provides data from satellite observation missions.</p>'
    },
    receivers: {
      name: 'Biotelemetry Receivers',
      svgIcon: 'satellite',
      mdIcon: '',
      description: '<p class="white-text mb-40px">Provides data from satellite observation missions.</p>'
    },
    dataset: {
      name: 'Dataset',
      svgIcon: 'satellite',
      mdIcon: '',
      description: '<p class="white-text mb-40px">Dataset data.</p>'
    },
    coastnetIndicators: {
      name: 'CoastNet Indicators',
      svgIcon: 'satellite',
      mdIcon: '',
      description: '<p class="white-text mb-40px">CoastNet Indicators data.</p>'
    }
  }

  constructor(private us: UtilitiesService, private ls: LayersService, private cs: CategoriesService, public dialog: MatDialog,
    private ts: TimelineService) {
    us.panelContentChanged$.subscribe(newViewType => {
      if (newViewType !== this.currentViewType) {
        this.isLoading = true;
        this.currentViewType = newViewType;
        if (this.currentViewType === 'dmof' && !this.us.hasService3InfoBeenViewed) {
          // this.showService3Dialog();
          this.us.hasService3InfoBeenViewed = true;
        } else if (this.currentViewType === 'layers' && !this.us.hasLayerInfoBeenViewed) {
          this.us.hasLayerInfoBeenViewed = true;
        }
      }
      this.setupStartTodayValue();
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.setupStartTodayValue();
    if (this.currentViewType === 'dmof' && !this.us.hasService3InfoBeenViewed) {
      // this.showService3Dialog();
      this.us.hasService3InfoBeenViewed = true;
    } else if (this.currentViewType === 'layers' && !this.us.hasLayerInfoBeenViewed) {
      this.us.hasLayerInfoBeenViewed = true;
    }
  }

  setSelectedSatelliteType(satelliteType: string) {
    this.selectedSatelliteType = satelliteType;
    //this.cs.manageUnwantedCategory(this.selectedSatelliteType); (To add later)
  }

  setupStartTodayValue() {
    if (this.currentViewType === 'layers') {
      this.ts.timelineStartToday = true;
    } else {
      this.ts.timelineStartToday = false;
    }
  }

  tabToLabel(tabIndex) {
    if (tabIndex === 0) {
      return 'forecast';
    } else if (tabIndex === 1) {
      return 'insitu';
    } else if (tabIndex === 2) {
      return 'satellite';
    }
  }

  onTabSwitch($event: MatTabChangeEvent) {
    this.selectedTab.name = $event.tab.textLabel;
    this.dialog.closeAll();
    if (this.selectedTab.name === 'receivers') {
      this.showBioDialog();
    } else if (this.selectedTab.name === 'dataset') {
      this.showHistoricDialog();
    }
  }

  showBioDialog() {
    this.showInfoDialog('', 'Biotelemetry Receivers',
      `Ongoing international collaboration with the European Tracking Network (ETN) resulted on the use
      of a common data portal that congregates the information gathered by local European tracking
      arrays, including national networks like the Portuguese Tracking Network.
      <a href="http://lifewatch.be/ptn/" target="_blank"> Access data portal.</a>`
    );
  }

  showHistoricDialog() {
    this.showInfoDialog('', 'Historical Data',
      `Historical data only support download, visualization option is not available. All the data (biological and environmental) will be downloaded in a single ZIP archive for the selected estuary`
    );
  }

  showInfoDialog(dialogIcon: string, dialogTitle: string, dialogInformation: string) {
    const dialogRef = this.dialog.open(QuickInfoBoxComponent, {
      width: '600px',
      autoFocus: false

    });
    dialogRef.componentInstance.icon = dialogIcon;
    dialogRef.componentInstance.title = dialogTitle;
    dialogRef.componentInstance.information = dialogInformation;
  }

  openInfoBox() {
    const dialogRef = this.dialog.open(QuickInfoBoxComponent, {
      width: '600px',
    });
    if (this.currentViewType === 'layers') {
      dialogRef.componentInstance.icon = this.selectedTab.svgIcon;
      dialogRef.componentInstance.title = this.selectedTab.name;
      dialogRef.componentInstance.information = this.selectedTab.description;
    } else {
      dialogRef.componentInstance.icon = this.visualizationMeta[this.currentViewType].mdIcon;
      dialogRef.componentInstance.title = this.visualizationMeta[this.currentViewType].name;
      dialogRef.componentInstance.information = this.visualizationMeta[this.currentViewType].description;
    }
  }

  closeSidenav() {
    this.closeContainer.emit();
  }

  get getCurrentDateTime(): string {
    if (this.ls.getCurrentDateTime !== undefined) {
      return moment.utc(this.ls.getCurrentDateTime).format('YYYY-MM-DD HH:mm');
    }
    return this.ls.getCurrentDateTime;
  }

}
