import { Component, OnInit, AfterViewInit, Injectable, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Layer, LayersService, MapService } from '../../shared';
import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
import { MatDialogRef } from '@angular/material';

import { GpSettings } from '../../shared/gp-settings';

declare var $: any;
declare var noUiSlider: any;
declare var wNumb: any;

@Component({
  selector: 'gp-layer-settings',
  templateUrl: './layer-settings.component.html',
  styleUrls: ['./layer-settings.component.css']
})
@Injectable()
export class LayerSettingsComponent implements OnInit, AfterViewInit {
  currentLayer: Layer;
  palettes: FirebaseListObservable<any[]>;
  styles: FirebaseListObservable<any[]>;

  constructor(private layersService: LayersService, private mapService: MapService, private af: AngularFireDatabase,
    public dialogRef: MatDialogRef<LayerSettingsComponent>, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.palettes = this.af.list('/palettes/' + Object.keys(this.layersService.getSettingsLayer.palettes)[0]);
    this.styles = this.af.list('/supportedStyles/' + Object.keys(this.layersService.getSettingsLayer.styles)[0]);
    this.currentLayer = this.layersService.getSettingsLayer;
  }

  ngAfterViewInit() {
  }

  get layerStyle(): string {
    if (this.currentLayer) {
      return this.currentLayer.currentStyle;
    } else {
      return '';
    }
  }

  set layerStyle(value: string) {
    if (this.currentLayer) {
      this.mapService.setStyle(this.currentLayer, value);
    }
  }

  get layerPalette(): string {
    if (this.currentLayer) {
      return this.currentLayer.currentPalette;
    } else {
      return '';
    }
  }

  set layerPalette(value: string) {
    if (this.currentLayer) {
      this.mapService.setPalette(this.currentLayer, value);
    }
  }

  get opacity(): number {
    if (this.currentLayer) {
      return this.currentLayer.opacity;
    } else {
      return 1;
    }
  }

  set opacity(value: number) {
    if (this.currentLayer) {
      this.mapService.setLayerOpacity(this.currentLayer, value);
    }
  }

  get minRange(): number {
    if (this.currentLayer) {
      return this.currentLayer.currentRange[0];
    } else {
      return 0;
    }
  }

  set minRange(value: number) {
    if (this.currentLayer && value < this.maxRange) {
      this.mapService.setColorbarRange(this.currentLayer, [value, this.currentLayer.currentRange[1]]);
    }
  }

  get maxRange(): number {
    if (this.currentLayer) {
      return this.currentLayer.currentRange[1];
    } else {
      return 1;
    }
  }

  set maxRange(value: number) {
    if (this.currentLayer && value > this.minRange) {
      this.mapService.setColorbarRange(this.currentLayer, [this.currentLayer.currentRange[0], value]);
    }
  }

  get minColorRange(): number {
    if (this.currentLayer) {
      return this.currentLayer.colorRange[0];
    } else {
      return 1;
    }
  }
  get maxColorRange(): number {
    if (this.currentLayer) {
      return this.currentLayer.colorRange[1];
    } else {
      return 1;
    }
  }

  paletteUrl(palette: string) {
    return this.sanitizer.bypassSecurityTrustUrl(
      GpSettings.NCWMS_URL + '?request=GetLegendGraphic&height=10&width=60&' +
      'numcolorbands=250&colorbaronly=true&vertical=false&palette=' + palette
    );
  }

}
