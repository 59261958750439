// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API_endpoint: 'http://api.coastnet.pt:3003/api/fetch',
  //API_endpoint: 'https://manager.coastnet.pt/api/fetch',
  NRT_doc: 'http://coastnet.fcul.cors.digital/wp-content/uploads/sites/7/2020/07/NRT-Products.zip',
  TS_doc: 'http://coastnet.fcul.cors.digital/wp-content/uploads/sites/7/2020/07/Time_Serie-Products.zip',
  insitu_doc: 'http://coastnet.fcul.cors.digital/wp-content/uploads/sites/7/2020/07/InSitu_Technical_Documentation.pdf',
  GA: 'UA-1075237-28'
};
