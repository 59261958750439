import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class TimelineService {

    private _currentTime; // Holds the currently selected time
    private _start;
    private _end;

    private aoiDrawing: boolean;

    private rangeChanged = new Subject();
    public rangeChanged$ = this.rangeChanged.asObservable();

    private currentChangedSource = new Subject();
    public currentChanged$ = this.currentChangedSource.asObservable();

    private isStartToday = false;
    private startTodayChangedSource = new Subject();
    public startTodayChanged$ = this.startTodayChangedSource.asObservable();

    public timeSeriesActivatedSource = new Subject();
    public timeSeriesActivated$ = this.timeSeriesActivatedSource.asObservable();


    constructor() { 
        this.aoiDrawing = false;
    }

    get start() {
        return this._start;
    }

    set start(date: Date) {
        if (!this._start || date.getTime() !== this._start.getTime()) {
            this._start = date;
            if (this._end) {
                this.rangeChanged.next([this._start, this._end]);
            }
        }
    }

    get end() {
        return this._end;
    }

    set end(date: Date) {
        if (!this._end || date.getTime() !== this._end.getTime()) {
            this._end = date;
            if (this._start) {
                this.rangeChanged.next([this._start, this._end]);
            }
        }
    }

    get currentTime() {
        return this._currentTime;
    }

    set currentTime(date: Date) {
        this._currentTime = date;
        this.currentChangedSource.next(date);
    }

    set timelineStartToday(newValue: boolean) {
        this.isStartToday = newValue;
        this.startTodayChangedSource.next(newValue);
    }

    get timelineStartToday(): boolean {
        return this.isStartToday;
    }

    set aoiDrawingEnabled(flag: boolean) {
        this.aoiDrawing = flag;
    }

    get aoiDrawingEnabled() {
        return this.aoiDrawing;
    }


}
