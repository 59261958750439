/**
 *
 * @author: tigm
 * @file: A component to display a tooltip on the map
 *
 */
import { Component, OnInit } from '@angular/core';
import { MapService } from '../../shared/map.service';
import { TooltipParameters } from './tooltip-parameters';
import { LayersService } from '../../shared/layers.service';

@Component({
  selector: 'gp-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.css']
})
export class MapTooltipComponent implements OnInit {
  tooltipOptions: TooltipParameters;

  /**
   * Initializes the tooltip component connecting to the map service to listen for tooltip change events.
   * @param ms Map service declaration
   */
  constructor(private ms: MapService, private ls: LayersService) {
    ms.tooltipVisibilityChanged$.subscribe(tooltipParams => {
      this.tooltipOptions = tooltipParams;
    });
    ls.layerVisibilityChanged$.subscribe(() => {
      if (this.tooltipOptions !== undefined && this.tooltipOptions.isVisible) {
        this.closeTooltip();
      }
    })
   }

  ngOnInit() {
  }

  /**
   * A method to close the tooltip
   */
  closeTooltip() {
    this.tooltipOptions.isVisible = false;
    this.ms.removePlotMarker();
  }

  /**
   * A method to show a plot for the currently selected pixel
   */
  showChart() {
    this.ms.showChart(this.tooltipOptions.point);
  }
}
