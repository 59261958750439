import { Component, OnInit, Input } from '@angular/core';
import { MapService } from '../shared/map.service';
@Component({
  selector: 'gp-map-info-tooltip',
  templateUrl: './map-info-tooltip.component.html',
  styleUrls: ['./map-info-tooltip.component.css']
})
export class MapInfoTooltipComponent implements OnInit {
  @Input() topPosition: string;
  @Input() leftPosition: string;
  visibility = 'hidden';
  defaultText = 'Click on the map to draw line.<br/> Click on the last point to finish.'
  drawAoiText = 'Click and hold, then drag the cursor on the map to define a rectangular area. <br/> Release to finish.'
  tooltipText;

  otherDrawingActive: boolean;
  constructor(private ms: MapService) { 
    this.otherDrawingActive = false;
    ms.drawingChanged$.subscribe(newValue => {
      if (newValue[1] === 1) {
        this.tooltipText = this.drawAoiText;
      } else {
        this.tooltipText = this.defaultText;
      }
      if (newValue[0] === true) {
        this.otherDrawingActive = true;
        this.visibility = 'visible';
      } else {
        this.otherDrawingActive = false;
        this.visibility = 'hidden';
      }
    })
  }

  ngOnInit() {
  }

  get latlngActive() {
    const status = this.ms.isLatlngCursorActive;
    if (!this.otherDrawingActive) {
      if (status) {
        this.visibility = 'visible';
      } else {
        this.visibility = 'hidden';
      }
    }
    return status;
  }

  get latlngText() {
    if (this.latlngActive && this.ms.latlngOfMap !== undefined) {
      return'<b>Lat:</b> ' + this.ms.latlngOfMap['lat'].toFixed(3) + '<br><b>Lon:</b> ' + this.ms.latlngOfMap['lng'].toFixed(3);
    } else {
      return '';
    }
  }
}
