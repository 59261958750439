import { Component, OnInit } from '@angular/core';
import { Layer, LayersService } from '../shared';


@Component({
  selector: 'gp-master-layer-selector',
  templateUrl: './master-layer-selector.component.html',
  styleUrls: ['./master-layer-selector.component.css']
})
export class MasterLayerSelectorComponent implements OnInit {

  constructor(private ls: LayersService) { }

  ngOnInit() {
  }

  get layers(): any {
    const visibleLayers = [];
    this.ls.getLayers.forEach(layer => {
      if (layer.isVisible) {
      }
      if (layer.isVisible && layer.isMasterCandidate && layer.category !== undefined) {
        if (layer.isDatasetLayer) {
          if (visibleLayers.find(lyr => lyr.category === layer.category) === undefined) {
            visibleLayers.push(layer)
          }
        } else {
          visibleLayers.push(layer)
        }
      }
    });
    return visibleLayers;
  }

  get masterLayer() {
    return this.ls.getMasterLayer;
  }

  set masterLayer(layer: Layer) {
    this.ls.setMasterLayer = layer;
    this.ls.emitMasterLayer();
  }

  getMasterVerbose(layer: Layer) {
    if (layer.category !== undefined && layer.category.parentCategory !== undefined) {
      if (layer.isDatasetLayer) {
        return layer.category.parentCategory.name + ' - ' + layer.category.name;
      } else {
        return layer.category.parentCategory.name + ' - ' + layer.category.name + ' - ' + layer.verboseId;
      }
    } else {
      return layer.verboseId;
    }
  }

}
