import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';

var FormData = require('form-data');
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private resultUrl: string;
  private status = '';
  private message: string;

  private availableProducts = ['ssh', 'sst', 'wave', 'wind', 'chl', 'mira1', 'mira2', 'mira3', 'mond1', 'mond2',
    'mond3', 'tejo1', 'tejo2', 'tejo3', 'ts_mira1', 'ts_mira2', 'ts_mira3', 'ts_mond1', 'ts_mond2',
    'ts_mond3', 'ts_tejo1', 'ts_tejo2', 'ts_tejo3', 'UI'];

  private availableSources = new Map();

  private availableOperations = {
    media: 'Mean',
    mediana: 'Median',
    standardeviation: 'Std Deviation',
    percentil10: 'Percentil 10',
    percentil90: 'Percentil 90',
    none: 'Download Data'
  }

  constructor(private http: HttpClient, private ga: GoogleAnalyticsService) {
    this.availableSources.set('Tejo', 'tagus');
    this.availableSources.set('CentralCoast', 'central_coast');
    this.availableSources.set('NorthCoast', 'northern_coast');
    this.availableSources.set('South-westCoast', 'south_western_coast');
    this.availableSources.set('Mira', 'mira');
    this.availableSources.set('Mondego', 'mondego');
    this.availableSources.set('RiaAveiro', 'ria_Aveiro');
    this.availableSources.set('RiaFormosa', 'ria_Formosa');
    this.availableSources.set('Sado', 'sado');
    this.availableSources.set('Minho', 'minho');
    this.availableSources.set('Guadiana', 'guadiana');
    this.availableSources.set('AlgarveCoast', 'algarve_coast');
    this.availableSources.set('Douro', 'douro');
    this.availableSources.set('LagoaObidos', 'lagoa_de_Óbidos');
  }


  get result() {
    return this.resultUrl;
  }

  get currentStatus() {
    return this.status;
  }

  get responseMessage() {
    return this.message;
  }

  get allowedOperations() {
    return this.availableOperations;
  }

  getAllowedOutputFormats(operation: string, layer?) {
    if (operation === 'none' && layer !== undefined && layer.isDatasetLayer) {
      return { zip: 'Zip File' }
    } else {
      return {
        netcdf: 'NetCDF',
        csv: 'CSV'
      }
    }
  }

  invokeProcessor(type: string, productType: string, productId: string,
    startDate: string, endDate: string, coordinates: any, operation: string, outputType: string) {
    let product, source, searchProduct;
    if (type === 'Dataset') {
      type = 'sensors';
      product = productId.split('_')[0];
      source = productId.split('_')[1]
      source = this.availableSources.get(source)
      productType = 'historicalData'
    } else {
      searchProduct = this.availableProducts.map((prod) => {
        return productId.includes(prod);
      });
      product = this.availableProducts[searchProduct.indexOf(true)];
      if (productType === 'insitu' || productType === 'insituTimeSeries') {
        source = product.charAt(0).toUpperCase() + product.slice(1, 4) + '_' + product.slice(4, 5);
        product = productId.split('/').pop();
      } else {
        source = 'all';
      }
    }
    if (coordinates !== 'all') {
      const coordinates_geojson = {
        'type': 'FeatureCollection',
        'features' : [
          coordinates
        ]
      }
      coordinates = JSON.stringify(coordinates_geojson);
    } else {
      coordinates = 'all';
    }
    if (productType === 'historicalData') {
      startDate = "01-01-2000" //workaround to get all data from dataset
    }
    const data = {
      'type': type,
      'productType': productType,
      'product': product,
      'source': source,
      'datainit': startDate,
      'datafinal': endDate,
      'coordinates': coordinates,
      'calculation': operation,
      'output': outputType
    };

    // No longer needed, API now only accepts json directly
    // const body = new FormData();
    // for (const key in data) {
    //   if (data.hasOwnProperty(key)) {
    //     body.append(key, data[key]);
    //   }
    // }

    this.resultUrl = undefined;
    this.status = 'Processing';
    this.message = 'Processing... please do not close or refresh this page';
    this.http.post(environment.API_endpoint, data).subscribe((response) => {
      this.status = 'Complete';
      if (response['msg'] === 'error') {
        this.message = response['msg'] + ':' + response['type']
      } else {
        this.ga.trackEvent("Download", "layer data download", productType+"|"+productId, "");
        this.message = 'Processing complete!'
        this.resultUrl = response['data']
      }
    }, (error) => {
      this.status = 'Error';
      this.message = error.error['type'];
    })

  }

  clear() {
    this.message = undefined;
    this.status = '';
    this.resultUrl = undefined;
  }
}
