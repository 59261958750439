import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'gp-chart-window',
  templateUrl: './chart-window.component.html',
  styleUrls: ['./chart-window.component.css']
})
export class ChartWindowComponent implements OnInit {
  chartTitle: string;
  isTransect: boolean;
  transectUrl: string;
  constructor(public dialogRef: MatDialogRef<ChartWindowComponent>) { }

  ngOnInit() {
  }

}
