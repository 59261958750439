import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gp-info-guide',
  templateUrl: './info-guide.component.html',
  styleUrls: ['./info-guide.component.css']
})
export class InfoGuideComponent implements OnInit {
  @Input() guideText: string;
  constructor() { }

  ngOnInit() {
  }

}
