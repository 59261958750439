import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Category } from './category';

@Injectable()
export class CategoriesService {
  private categories: Category[] = [];
  private tempCategory: Category;

  private categorySelectedSource = new Subject<Category>();

  categorySelected$ = this.categorySelectedSource.asObservable();

  emitSelected(selectedCategory: Category) {
    this.categorySelectedSource.next(selectedCategory);
  }

  constructor() { }

  get availableCategories(): Category[] {
    return this.categories;
  }

  addCategory(category: Category, positionToInsert?: number) {
    if (!positionToInsert) {
      this.categories.unshift(category);
    } else {
      this.categories.splice(positionToInsert,0,category);
    }
  }

  findCategory(categoryId: string): Category {
    return this.categories.find((thisCategory) => thisCategory.id === categoryId);
  }

  removeCategory(category: Category) {
    this.categories.forEach((item, index) => {
      if (item === category) {
        const a = this.categories.splice(index, 1);
      }
    });
  }

  sortCategories() {
    this.categories.sort(function (a, b) {
      return (a.zIndex < b.zIndex) ? 1 : ((b.zIndex < a.zIndex) ? -1 : 0);
    });
  }

  emptyCategories() {
    if (this.categories.length > 0) {
      this.categories = [];
    }

  }

  resetCategories() {
    for (const cat of this.categories) {
      cat.numVisibleLayers = 0;
      cat.hasVisibleLayers = false;
    }
  }

  manageUnwantedCategory(satelliteType: string) { // Remove sea surface salinity from NRT
    if (!this.tempCategory) {
      this.tempCategory = this.findCategory('sss');
    }
    if (satelliteType === 'Near Real Time' && this.tempCategory) {
      this.removeCategory(this.tempCategory);
    } else if (satelliteType === 'Time Series' && !this.findCategory('sss')) {
      this.addCategory(this.tempCategory, 2);
    }
  }


}
