import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Layer } from '../shared/layer';
import { LayersService } from '../shared/layers.service';
import { MapService } from '../shared/map.service';


import { GpSettings } from '../shared/gp-settings';
import { TutorialService } from '../shared/tutorial.service';

@Component({
  selector: 'gp-colorbar',
  templateUrl: './colorbar.component.html',
  styleUrls: ['./colorbar.component.css']
})
export class ColorbarComponent implements OnInit {

  NCWMS_URL: string;
  masterLayer: Layer;
  maxValue: Number = undefined;
  minValue: Number = undefined;
  savedValue: Number = undefined;
  isLogScale = false;
  colorRangeMin: Number;
  colorRangeMax: Number;

  constructor(private layersService: LayersService, private mapService: MapService, private tutorial: TutorialService, public snackBar: MatSnackBar) {
    this.layersService.masterLayerChanged$.subscribe(
      newLayer => {
        this.masterLayer = newLayer;
        if (this.masterLayer !== undefined) {
          if (this.masterLayer.colorRange !== null && this.masterLayer.currentRange !== null) {
            if (this.masterLayer.defaultColorRange === undefined && this.masterLayer.defaultCurrentRange === undefined) {
              this.masterLayer.defaultColorRange = this.masterLayer.colorRange;
              this.masterLayer.defaultCurrentRange = this.masterLayer.currentRange;
            }
            this.colorRangeMin = this.masterLayer.colorRange[0];
            this.colorRangeMax = this.masterLayer.colorRange[1];
            this.maxValue = this.masterLayer.currentRange[1];
            this.minValue = this.masterLayer.currentRange[0];
          }
          this.isLogScale = false;
          this.mapService.toggleLogScale(this.masterLayer, this.isLogScale);
        }

      }
    );
  }

  ngOnInit() {
    this.NCWMS_URL = GpSettings.NCWMS_URL;
  }

  onLogScaleChanged(value: any) {
    if (this.isLogScale && this.minValue <= 0) {
      this.snackBar.open('Please use a minimum value greater than 0 when using a log scale', 'OK');
      this.minValue = this.savedValue ? this.savedValue : this.minValue;
      return
    }

    this.mapService.toggleLogScale(this.masterLayer, this.isLogScale)
  }

  onMinValueChanged() {
    if (this.isLogScale && this.minValue <= 0) {
      this.snackBar.open('Please use a minimum value greater than 0 when using a log scale', 'OK');
      this.minValue = this.savedValue ? this.savedValue : this.minValue;
      return
    }

    if (this.minValue !== undefined) {
      this.savedValue = this.minValue;
      this.mapService.setColorbarRange(this.masterLayer, [Number(this.minValue), Number(this.maxValue)]);
    }
  }

  onMaxValueChanged() {
    if (this.maxValue !== undefined) {
      this.mapService.setColorbarRange(this.masterLayer, [Number(this.minValue), Number(this.maxValue)]);
    }
  }

  isInt(value: any) {
    return !isNaN(value) && (Number(value)) == value && !isNaN(Number(value));
  }

  reloadColorbar(image) {
    image.onerror = "";
    image.src = "{{NCWMS_URL}}?request=GetLegendGraphic&height=255&width=30&numcolorbands=250&colorbaronly=true&vertical=true&palette={{masterLayer?.currentPalette}}";
    return true;
  }

  resetRange() {
    this.minValue = this.masterLayer.defaultCurrentRange[0];
    this.maxValue = this.masterLayer.defaultCurrentRange[1];
    this.colorRangeMin = this.masterLayer.defaultColorRange[0];
    this.colorRangeMax = this.masterLayer.defaultColorRange[1];
    this.onMinValueChanged()
    this.onMaxValueChanged()
    this.isLogScale = false;
    this.onLogScaleChanged(false)
  }

}
