import { Category } from './category';

export class Layer {
    constructor(
        public id: string,
        public simpleId: string,
        public verboseId: string,
        public name: string,
        public description: string,
        public palettes: Object,
        public styles: Object,
        public currentStyle: string,
        public currentPalette: string,
        public availableDates: Object,
        public nearestTime: string,
        public units: string,
        public colorRange: number[],
        public currentRange: number[],
        public bbox: number[],
        public isSelected: boolean,
        public isVisible: boolean,
        public opacity: number,
        public zIndex: number,
        public leafletSource: any,
        public category: Category,
        public listPosition: number,
        public tooltip: string,
        public marker: any = null,
        public markerDot: any,
        public type: string,
        public productType: string,
        public isMasterCandidate: boolean,
        public isDatasetLayer?: boolean,
        public defaultColorRange?: number[],
        public defaultCurrentRange?: number[]
    ) {}

    get startDate() {
        const dateArray = [];
        for (const _year in this.availableDates) {
            if (this.availableDates.hasOwnProperty(_year)) {
                for (const _month in this.availableDates[_year]) {
                    if (this.availableDates[_year].hasOwnProperty(_month)) {
                        for (const _day of this.availableDates[_year][_month]) {
                            dateArray.push(_year + '-' + (Number(_month) + 1) + '-' + _day);
                        }
                    }
                }
            }
        }

        return dateArray;
    }
}
