import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'gp-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  private termsAccepted: boolean;
  constructor(public dialogRef: MatDialogRef<DisclaimerComponent>) {
    this.termsAccepted = false;
  }

  ngOnInit() {
  }

checkTerms(event: any) {
  this.termsAccepted = event['checked'];
}

}
