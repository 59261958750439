import { Component, AfterViewInit, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { trigger, transition, animate, state, style } from '@angular/animations'
import { MapComponent } from './map/map.component';
import { MatIconRegistry, MatIconModule } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilitiesService } from './shared/utilities.service';
import { LayersService } from './shared/layers.service';
import { MapService } from './shared/map.service';
import { MasterLayerSelectorComponent } from './master-layer-selector/master-layer-selector.component';
import { MatDialog } from '@angular/material';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { UserPolicyComponent } from './user-disclaimer/user-policy/user-policy.component';
import { LayerContainerComponent } from './layer-container/layer-container.component';
import { InfoGuideComponent } from './info-guide/info-guide.component';
import { MapInfoTooltipComponent } from './map-info-tooltip/map-info-tooltip.component';
import { TimelineService } from './shared/timeline.service';
import { ApiService } from './shared/api.service';
import { CategoriesService, ParentCategoryService, GoogleAnalyticsService } from './shared/';
import { TutorialService } from './shared/tutorial.service';

declare var require: any;
declare var introJs: any;
@Component({
  selector: 'gp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger(
      'rotateService', [
        transition('close => services', animate('300ms', style({ transform: 'rotate(180deg)' }))),
        transition('services => close', animate('300ms', style({ transform: 'rotate(180deg)' })))
      ]
    ),
    trigger(
      'rotateTools', [
        transition('close => tools', animate('300ms', style({ transform: 'rotate(180deg)' }))),
        transition('tools => close', animate('300ms', style({ transform: 'rotate(180deg)' })))
      ]
    ),
    trigger(
      'rotateTimeline', [
        transition('close => timeline', animate('300ms', style({ transform: 'rotate(180deg)' }))),
        transition('timeline => close', animate('300ms', style({ transform: 'rotate(180deg)' })))
      ]
    ),
    trigger('timelineAppear', [
      state('in', style({ height: '*' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(200, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: 0 }),
        animate(200, style({ height: '*' }))
      ]),
    ]),
    trigger('timelineButtonMove', [
      state('normal', style({ bottom: '2rem' })),
      state('open', style({ bottom: '95px' })),
      transition('normal => open', [
        animate('200ms', style({
          bottom: '95px'
        }))
      ]),
      transition('open => normal', [
        animate('200ms', style({
          bottom: '2rem'
        }))
      ])
    ])
  ],
})

export class AppComponent implements OnInit, AfterViewInit {
  sidenavId: string;
  isTimelineEnabled = false;
  isServiceActive = false;
  isToolsActive = false;
  isLoaderActive = false;
  isBackdropHidden = false;
  isCalculatorEnabled = false;
  valueBaseMap: string = "oceans";
  iconBaseMap: string = "satellite";
  toolTipBaseMap: string = "Satellite";
  @ViewChild(MapInfoTooltipComponent)
  private mapInfoTooltipComponent: MapInfoTooltipComponent;
  @ViewChild('sidenav') sidenavElement;


  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private us: UtilitiesService,
    public dialog: MatDialog, private ls: LayersService, private ms: MapService, private tl: TimelineService, private ga: GoogleAnalyticsService,
    private cs: CategoriesService, private capi: ApiService, private ps: ParentCategoryService, private tutorial: TutorialService) {
    iconRegistry.addSvgIcon(
      'signal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/signal.svg'));
    iconRegistry.addSvgIcon(
      'buoy',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/buoy.svg'));
    iconRegistry.addSvgIcon(
      'buoys',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/buoys.svg'));
    iconRegistry.addSvgIcon(
      'satellite',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/satellite.svg'));
    iconRegistry.addSvgIcon(
      'pressure',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pressure.svg'));
    iconRegistry.addSvgIcon(
      'wave_direction',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wave_direction.svg'));
    iconRegistry.addSvgIcon(
      'wave_period',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wave_period.svg'));
    iconRegistry.addSvgIcon(
      'rain',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/rain.svg'));
    iconRegistry.addSvgIcon(
      'sea_temperature',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/sea_temperature.svg'));
    iconRegistry.addSvgIcon(
      'sea_wind',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/sea_wind.svg'));
    iconRegistry.addSvgIcon(
      'wave_height',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wave_height.svg'));
    iconRegistry.addSvgIcon(
      'air_temperature',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/air_temperature.svg'));
    iconRegistry.addSvgIcon(
      'cloud',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/cloud.svg'));
    iconRegistry.addSvgIcon(
      'fish',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/fish.svg'));
    iconRegistry.addSvgIcon(
      'fish2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/fish2.svg'));
    iconRegistry.addSvgIcon(
      'invertebrate',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/invertebrate2.svg'));
    iconRegistry.addSvgIcon(
      'macroalgae',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/macroalgae.svg'));
    iconRegistry.addSvgIcon(
      'phyto',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/phyto.svg'));
    iconRegistry.addSvgIcon(
      'saltmarsh',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/saltmarsh.svg'));
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/close.svg'));
    iconRegistry.addSvgIcon(
      'timeline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/timeline.svg'));
    iconRegistry.addSvgIcon(
      'harbour',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/harbour.svg'));
    iconRegistry.addSvgIcon(
      'services',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/services.svg'));
    iconRegistry.addSvgIcon(
      'tools',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tools.svg'));
    iconRegistry.addSvgIcon(
      'draw_poly',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/draw_poly.svg'));
    iconRegistry.addSvgIcon(
      'fishing',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/fishing.svg'));
    iconRegistry.addSvgIcon(
      'metocean',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/metocean.svg'));
    iconRegistry.addSvgIcon(
      'swell',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/swell.svg'));
    iconRegistry.addSvgIcon(
      'salt',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/salt.svg'));
    iconRegistry.addSvgIcon(
      'tsunami',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tsunami.svg'));
    iconRegistry.addSvgIcon(
      'water_depth',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/water_depth.svg'));
    iconRegistry.addSvgIcon(
      'wave_peak',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wave_peak.svg'));
    iconRegistry.addSvgIcon(
      'wind',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wind.svg'));
    iconRegistry.addSvgIcon(
      'wavelength',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/wavelength.svg'));
    iconRegistry.addSvgIcon(
      'tide',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tide.svg'));
    iconRegistry.addSvgIcon(
      'tidegauge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/tidegauge.svg'));
    iconRegistry.addSvgIcon(
      'waves',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/waves.svg'));
    iconRegistry.addSvgIcon(
      'data_download',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/cloud_download.svg'));
    iconRegistry.addSvgIcon(
      'pointer_location',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pointer_location.svg'));
    iconRegistry.addSvgIcon(
      'location_searching',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/location_searching.svg'));
    this.isBackdropHidden = true;

    //   const dialogRef = this.dialog.open(DisclaimerComponent, {
    //   width: '60%',
    //   disableClose: true
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.isBackdropHidden = true;
    //   introJs().start()
    // });

  }

  ngOnInit() {
    if (localStorage.getItem('userAccepted') === "true") {
      this.ga.startTracking();
    } else if (localStorage.getItem('userAccepted') === null) {
      this.dialog.open(UserPolicyComponent, { disableClose: true, width: '800px',});
    }
  }

  ngAfterViewInit() {
    this.onSidenavToggle(this.sidenavElement, 'dmof')
  }

  onSidenavToggle(sidenav: any, sidenavId: string) {
    this.serviceHover(false);
    this.toolsHover(false);

    this.us.panelContentChangedSource.next(sidenavId);

    if (this.sidenavId === sidenavId) {
      sidenav.close();
      this.sidenavId = undefined;
    } else {
      if (this.sidenavId === undefined) {
        sidenav.open();
      }
      this.sidenavId = sidenavId;
    }
  }

  closeSidenav(sidenav: any) {
    if (this.ls.getMasterLayer === undefined) {
      this.ms.setCurrentcursor('grab')
      this.ms.showDefaultCursor();
    }
    sidenav.close();
    this.sidenavId = undefined;
  }

  serviceHover(isActive?: boolean) {
    if (typeof (isActive) !== 'undefined') {
      this.isServiceActive = isActive;
    } else {
      this.isServiceActive = !this.isServiceActive;
    }
  }

  toolsHover(isActive?: boolean) {
    if (typeof (isActive) !== 'undefined') {
      this.isToolsActive = isActive;
    } else {
      this.isToolsActive = !this.isToolsActive;
    }
  }

  clearWorkspace(iconBaseMap) {
    this.ms.clearMap(iconBaseMap);
    if (this.sidenavId === undefined) {
      this.ms.setCurrentcursor('grab')
      this.ms.showDefaultCursor();
    }
    this.ls.resetLayers();
    this.cs.resetCategories();
    this.ps.resetParentCategories();
    this.iconBaseMap = 'satellite';
    this.capi.clear();
    this.ms.emitLoading(false);
  }

  drawPolyline() {
    this.ms.drawTransect();
    // this.mapInfoTooltipComponent.visibility = 'visible';
  }

  get isTimeseriesOpen(): boolean {
    return this.ms.isTimeSeriesOpen;
  }

  get isTimelineOpen(): boolean {
    return this.isTimelineEnabled || this.isTimeseriesOpen;
  }

  get isColorbarActive(): boolean {
    const mLayer = this.ls.getMasterLayer
    return mLayer !== undefined && !mLayer.isDatasetLayer && mLayer.leafletSource instanceof Object
  }

  get apiStatus() {
    return this.capi.currentStatus;
  }

  toggleTimeline(calculator: boolean) {
    if ((!this.isTimeseriesOpen && calculator === this.isCalculatorEnabled) || this.isTimelineEnabled === false) {
      this.isTimelineEnabled = !this.isTimelineEnabled;
    }
    this.isCalculatorEnabled = calculator;
    if (this.isCalculatorEnabled) {
      this.tl.timeSeriesActivatedSource.next(true);
    } else {
      this.tl.timeSeriesActivatedSource.next(false)
    }
  }

  toggleLatlngCursor() {
    this.ms.isLatlngCursorActive = !this.ms.isLatlngCursorActive
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: any) {
    this.mapInfoTooltipComponent.topPosition = event.clientY - 40 + 'px';
    this.mapInfoTooltipComponent.leftPosition = event.clientX + 6 + 'px';
  }

  onClickChangeBaseMap() {
    this.ms.clearMap(this.iconBaseMap)
    if (this.iconBaseMap === "map") {
      this.iconBaseMap = "satellite";
      this.toolTipBaseMap = "Satellite";
      this.valueBaseMap = "satellite";
      this.reloadMap(this.valueBaseMap);
    } else {
      this.iconBaseMap = "map";
      this.toolTipBaseMap = "Bathymetry";
      this.valueBaseMap = "oceans";
      this.reloadMap(this.valueBaseMap);
    }
    for (const layer of this.ls.getLayers) {
      if (layer.isVisible) {
        if (layer.leafletSource instanceof Object) {
          this.ms.addLayerToMap(layer)
        } else if (layer.isDatasetLayer) {
          this.ms.addMapMarker(layer.bbox[0], layer.bbox[1], '1', false, layer, layer.name);
        } else if (layer.markerDot === undefined) {
          // insitu
          this.ms.addMapMarker(layer.category.bounds[0], layer.category.bounds[1], '1', false, layer, layer.name);
        } else {
          // receivers
          this.ms.addMapCircle(layer.bbox[0], layer.bbox[1], 500, false, layer, layer.name);
        }
      }
    }
    if (this.ls.getMasterLayer !== undefined && this.ls.getMasterLayer.leafletSource !== null) {
      this.ls.getMasterLayer.leafletSource.bringToFront();
    }
  }

  private reloadMap(basemap: string) {
    this.ms.createMap('map');
    this.ms.addBasemap(basemap);
  }

  startTutorial(startStep: number) {
    if (this.sidenavId === undefined) {
      this.tutorial.run(startStep);
    } else {
      this.tutorial.run(startStep, 1);
    }
  }

  get latlngCursor() {
    if (this.ms.latlngOfMap !== undefined) {
      return [this.ms.latlngOfMap['lat'].toFixed(3), this.ms.latlngOfMap['lng'].toFixed(3)];
    } else {
      return [0, 0];
    }
  }

}
