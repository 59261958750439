import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ParentCategory } from './parent-category';

@Injectable()
export class ParentCategoryService {
  private parentCategories: ParentCategory[] = [];

  private ParentCategorySelectedSource = new Subject<ParentCategory>();

  ParentCategorySelected$ = this.ParentCategorySelectedSource.asObservable();

  emitSelected(selectedParentCategory: ParentCategory) {
    this.ParentCategorySelectedSource.next(selectedParentCategory);
  }

  constructor() { }

  get availableParentCategories(): ParentCategory[] {
    return this.parentCategories;
  }

  addParentCategory(parentCategory: ParentCategory) {
    this.parentCategories.unshift(parentCategory);
  }

  findParentCategory(parentCategoryId: string): ParentCategory {
    return this.parentCategories.find((thisParentCategory) => thisParentCategory.id === parentCategoryId);
  }
  sortCategories() {
    this.parentCategories.sort(function (a, b) {
      return (a.zIndex < b.zIndex) ? 1 : ((b.zIndex < a.zIndex) ? -1 : 0);
    });
  }

  emptyParentCategories() {
    if (this.parentCategories.length > 0) {
      this.parentCategories = [];
    }
  }

  resetParentCategories() {
    for (const cat of this.parentCategories) {
      cat.numVisibleLayers = 0;
      //cat.hasVisibleLayers = false;
    }
  }


}
