import { Injectable } from '@angular/core';


declare var introJs: any;
@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  private context: number;
  private stepMap: Map<number, number>;
  private currentStep: number;
  private tutorialInstance: any;
  private numContexts: number;

  constructor() {
    // to scale the tutorial service, simply add additional entries in the step map for every new context.
    // the mapping is <context_number, first_tutorial_step>
    this.stepMap = new Map();
    this.stepMap.set(0, 1);
    this.stepMap.set(1, 8);
    this.stepMap.set(2, 13);
    this.stepMap.set(3, 14);
    this.stepMap.set(4, 20);
    this.stepMap.set(5, 27);

    this.numContexts = this.stepMap.size;
  }

  run(context: number, skipNsteps?: number) {
    this.context = context;
    this.currentStep = this.startStep;
    if (skipNsteps !== undefined) {
      this.currentStep += skipNsteps;
    }
    this.tutorialInstance = introJs()
    this.tutorialInstance.setOptions({
      overlayOpacity: 0.5,
      showStepNumbers: false,
      showBullets: false,
      prevLabel: '<',
      nextLabel: '>',
      skipLabel: 'Exit',
      doneLabel: 'Exit'
    })
    this.tutorialInstance.start();
    this.tutorialInstance.goToStepNumber(this.currentStep)
    setTimeout(this.tutorialInstance.onbeforechange((elem) => {
      this.currentStep = parseInt(elem.attributes['data-step'].value)
    }), 100)
    this.tutorialInstance.onafterchange(() => {
      if ((this.endStep !== null && (this.currentStep > this.endStep || this.currentStep < this.startStep))
             || this.endStep === null && this.currentStep < this.startStep) {
        this.tutorialInstance.exit();
      }
    })
  }

  get startStep() {
    return this.stepMap.get(this.context);
  }

  get endStep() {
    if (this.context < this.numContexts - 1) {
      return this.stepMap.get(this.context + 1) - 1;
    } else {
      return null;
    }
  }

}


