import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'gp-quick-info-box',
  templateUrl: './quick-info-box.component.html',
  styleUrls: ['./quick-info-box.component.css']
})
export class QuickInfoBoxComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() information: string;

  constructor(public dialogRef: MatDialogRef<QuickInfoBoxComponent>) { }

  ngOnInit() {
  }

}
