import { Injectable } from '@angular/core';
import { LatLng } from './lat-lng-coordinates';

@Injectable({
  providedIn: 'root'
})
export class SelectaoiService {

  public selectingAoI: Boolean;
  public boundingBox: LatLng[];
  public requestBoundingBox: any;

  constructor() { }


  getAoIflag(){
    return this.selectingAoI;
  }
  setBoundingBox(bb){
    this.boundingBox = bb;
  }

  getBoundingBox(){
    return this.boundingBox;
  }

  isAoIValid() {
    if (this.boundingBox === undefined || this.boundingBox.length < 4) {
      return false
    }
    return true
  }

  getRequestBountingBox(): any {
    return this.requestBoundingBox;
  }

  clearBoundingBox() {
    this.boundingBox = undefined;
  }
}
