import { Component, OnInit, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { AngularFireDatabase } from 'angularfire2/database';
import { MapService, Category, ParentCategory, Layer, CategoriesService, ParentCategoryService, LayersService, UtilitiesService, GoogleAnalyticsService } from '../shared';
import { MatDialog } from '@angular/material';
import { LayerSettingsComponent } from './layer-settings/layer-settings.component';
import { environment } from '../../environments/environment';
declare var moment: any;

@Component({
  selector: 'gp-layers-list',
  templateUrl: './layers-list.component.html',
  styleUrls: ['./layers-list.component.css'],
  animations: [
    trigger(
      'rotateArrow', [
        state('open', style({
          'transform': 'rotate(90deg)'
        })),
        state('closed', style({
          'transform': 'rotate(0deg)'
        })),
        transition('open => closed', animate('200ms')),
        transition('closed => open', animate('200ms'))
      ]
    ),
    trigger(
      'listSlide', [
        transition(':leave', [
          style({ height: '*', opacity: 1 }),
          animate('200ms', style({
            opacity: 0,
            height: 0
          }))
        ]),
        transition(':enter', [
          style({ height: 0, opacity: 0 }),
          animate('200ms', style({
            opacity: 1,
            height: '*'
          }))
        ]),
      ])]
})
export class LayersListComponent implements OnInit, AfterViewInit, OnDestroy {
  parentCategories: ParentCategory[] = [];
  biologicalCat: Category[] = [];
  environmentalCat: Category[] = [];
  categories: Category[] = [];
  openCategory: Category;
  openChildCategory: Category;
  openParentCategory: ParentCategory;
  categoryDragEnabled = true;
  coastnetIndicatorLayers: Layer[] = [];
  layersLoaded: Boolean = false;
  tempCategory: Category;
  @Input() currentViewType: string;
  @Input() categoryIndex: number;
  @Input() parentViewType: string;
  @Input() satelliteType: string;
  isLoading = false;

  private ssiValuesList = {
    'Figueira da Foz': undefined,
    'Aveiro': undefined,
    'Póvoa de Varzim': undefined,
    'viana': undefined
  }

  private colorCodes = [
    '#4CAF50',
    '#FFEB3B',
    '#FF9800',
    '#F44336'
  ]
  private ssiDescription = [
    'Open without restictions',
    'Restricted, 11 m',
    'Restricted, 35 m',
    'Closed'
  ]

  constructor(private af: AngularFireDatabase, private cs: CategoriesService, private ps: ParentCategoryService, private ls: LayersService,
    private ms: MapService, public dialog: MatDialog, private ga: GoogleAnalyticsService) {
    ls.currentDateChanged$.subscribe(
      () => {
        this.fetchSSIValues();
      }
    );

    ls.currentTimeChanged$.subscribe(
      () => {
        this.fetchSSIValues();
      }
    );
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.isLoading = true
    if (this.parentViewType === 'dmof') {
      this.ms.isPlotActive = true;
      this.ms.setCurrentcursor('crosshair');
      this.ms.showCrosshairCursor();
    } else {
      this.ms.removePlotMarker();
      this.ms.setCurrentcursor('default');
      this.ms.showDefaultCursor();
      this.ms.isPlotActive = false;
    }
    if (this.currentViewType === 'insitu' || this.currentViewType === 'dataset') {
      setTimeout(() => {
        this.getGeneralCategoryInformation(this.currentViewType);
        this.markVisibleLayers();
      }, 1);
    } else {
      setTimeout(() => {
        this.getCategoryInformation(this.currentViewType);
        this.markVisibleLayers();
      }, 1);
    }
    this.isLoading = false;

    //setTimeout(() => this.cs.manageUnwantedCategory(this.satelliteType), 500); (To implement later)
  }

  ngOnDestroy() {
    this.currentViewType = undefined;
  }

  loadSoloLayers() {
    let layerListPosition = 0;
    let layerZIndex = 1;

    this.af.object('/indicators/').subscribe(indicators => {
      for (const layer in indicators.layers) {
        if (indicators.layers.hasOwnProperty(layer)) {
          this.getSoloLayerInformation(layer, layerZIndex, layerListPosition);
          layerZIndex += 1;
          layerListPosition += 1;
        }
      }
    });
  }

  markVisibleLayers() {
    const availableParentCategories = this.ps.availableParentCategories;
    const availableCategories = this.cs.availableCategories;
    const visibleDatasets = this.ls.getVisibleDatasetLayers;
    for (const cat of availableCategories) {
      const datasetsOfCategory = visibleDatasets.filter((ds) => ds.category.id === cat.id &&
        ds.category.parentCategory.id === cat.parentCategory.id)
      if (datasetsOfCategory.length > 0) {
        cat.numVisibleLayers = datasetsOfCategory.length;
      }
      for (const lyr of cat.layers) {
        if (lyr.isVisible) {
          cat.numVisibleLayers += 1;
        }
      }
      cat.hasVisibleLayers = cat.numVisibleLayers > 0;
    }
    for (const pCat of availableParentCategories) {
      pCat.numVisibleLayers = 0;
      for (const subCat of pCat.subCategory) {
        pCat.numVisibleLayers += subCat.numVisibleLayers;
      }
    }
  }

  disableCategory(category: Category) {
    category.isDisabled = category.layers.length === 0;
  }

  isSatelliteCatDisabled(category: Category, satType: string) {
    const layers = category.layers;
    if (satType === 'Near Real Time') {
      return layers.find(lyr => lyr.id.lastIndexOf('nrt', 0) === 0) === undefined;
    } else if (satType === 'Time Series') {
      return layers.find(lyr => lyr.id.lastIndexOf('nrt', 0) === -1) === undefined;
    } else {
      return true
    }
  }

  getGeneralCategoryInformation(endpoint: string) {
    this.ps.emptyParentCategories();
    this.cs.emptyCategories();
    // this.ms.clearSSIMarkers();
    this.ms.clearMapPath();
    this.isLoading = true;
    if (endpoint === 'insitu') {
      this.af.list('/' + endpoint + '/').subscribe(availableCategories => {
        if (availableCategories.length > 0) {
          for (let i = availableCategories.length - 1; i >= 0; i--) {
            const cat = availableCategories[i];
            let currentParentCategory = this.ps.findParentCategory(cat.$key);
            if (!currentParentCategory) {
              currentParentCategory = new ParentCategory(
                cat.$key,
                cat.name,
                cat.icon,
                undefined,
                [],
                undefined,
                undefined, undefined, undefined, cat.listPosition, undefined, undefined, cat.disabled, cat.details
              );
              this.ps.addParentCategory(currentParentCategory);
            }
          }
          this.parentCategories = this.ps.availableParentCategories;
          this.parentCategories.sort((cat1, cat2) => cat1.listPosition - cat2.listPosition);
        } else {
          this.parentCategories = [];
        }
        this.isLoading = false;
        this.getCategoryInformation(endpoint);
      });
    } else { //dataset
      this.af.list('/' + endpoint + '/').subscribe(availableCategories => {
        if (availableCategories.length > 0) {
          for (let i = availableCategories.length - 1; i >= 0; i--) {
            const cat = availableCategories[i];
            let currentParentCategory = this.ps.findParentCategory(cat.$key);
            if (!currentParentCategory) {
              currentParentCategory = new ParentCategory(
                cat.$key,
                cat.name,
                cat.icon,
                cat.description,
                [],
                undefined,
                cat.bounds, cat.id, undefined, undefined, undefined, 0, cat.disabled, cat.ecosystem
              );
              this.ps.addParentCategory(currentParentCategory);
            }
          }
          this.parentCategories = this.ps.availableParentCategories;
        } else {
          this.parentCategories = [];
        }
        this.isLoading = false;
        this.getSubCategoryInformation();
        if (endpoint === 'dataset') {
          this.getDatasetInformation();
        }
      });
    }
  }

  getParametersCategoryInformation(categoryZIndex: number) {
    let layerZIndex = 1;
    this.af.list('/dataset_layers_ref/').subscribe(availableCategories => {
      if (availableCategories.length > 0) {
        for (let i = availableCategories.length - 1; i >= 0; i--) {
          const cat = availableCategories[i];
          if (cat.layers !== undefined && cat.layers.length !== 0) {
            let currentCategory = this.cs.findCategory(cat.$key);
            if (!currentCategory) {
              currentCategory = new Category(
                cat.$key,
                cat.name,
                cat.icon,
                cat.description,
                [],
                categoryZIndex,
                cat.bounds, undefined, undefined, undefined, undefined, 0, cat.disabled
              );
              let layerListPosition = 0;
              for (const layer in cat.layers) {
                if (cat.layers.hasOwnProperty(layer)) {
                  this.getLayerInformation(currentCategory, layer, layerZIndex, layerListPosition);
                  layerZIndex += 1;
                  layerListPosition += 1;
                }
              }
              for (const parentCategory of this.parentCategories) {
                for (const category of parentCategory.subCategory) {
                  if (cat.$key.indexOf('biological') >= 0 && category.biological === cat.$key) {
                    category.addBiologicalCategory(currentCategory);
                  } else if (cat.$key.indexOf('enviro') >= 0 && category.environmental === cat.$key) {
                    category.addEnvironmentalCategory(currentCategory);
                  }
                }
              }
              if (cat.$key.indexOf('biological') >= 0) {
                this.biologicalCat.unshift(currentCategory);
              } else {
                this.environmentalCat.unshift(currentCategory);
              }
            }
          }
          categoryZIndex++;
        }
      }
    });
  }

  getSubCategoryInformation() {
    let categoryZIndex = 1;
    this.isLoading = true;

    for (let i = 0; i !== this.parentCategories.length; i++) {
      this.af.list('/' + this.parentCategories[i].subCatId + '/').subscribe(availableCategories => {
        const parent = this.parentCategories[i];
        if (availableCategories.length > 0) {
          for (let i = availableCategories.length - 1; i >= 0; i--) {
            const cat = availableCategories[i];
            if (cat.biological !== undefined && cat.environmental !== undefined) {
              const currentCategory = new Category(
                cat.$key,
                cat.name,
                cat.icon,
                cat.description,
                [],
                categoryZIndex,
                [], parent, undefined, cat.listPosition, cat.group, 0, cat.disabled,
                cat.biological, cat.environmental, undefined, [], []
              );
              this.cs.addCategory(currentCategory);
              parent.addCategory(currentCategory);
            }
            categoryZIndex += 1;
          }
          this.categories = this.cs.availableCategories;
        } else {
          this.categories = [];
        }
        parent.subCategory.sort((cat1, cat2) => cat1.listPosition - cat2.listPosition)
        if (i === this.parentCategories.length - 1) {
          this.getParametersCategoryInformation(categoryZIndex);
        }
      });
    }
  }

  getCategoryInformation(endpoint: string) {
    this.cs.emptyCategories();

    let categoryZIndex = 1;
    let layerZIndex = 1;
    if (endpoint === 'forecast' || endpoint === 'insitu' || endpoint === 'pfa') {
      this.isLoading = true;
    }
    // this.ms.clearSSIMarkers();
    this.ms.clearMapPath();

    if (this.currentViewType === 'forecast' && moment.utc().isAfter(moment.utc(this.ls.getCurrentDateTime), 'day')) {
      // this.ls.resetLayerVisibility();
      this.ls.setCurrentDate = undefined;
      this.ls.setCurrentTime = undefined;
    }

    if (endpoint === 'insitu') {
      for (let i = 0; i !== this.parentCategories.length; i++) {
        this.af.list('/' + this.parentCategories[i].subCatId + '/').subscribe(availableCategories => {
          const parent = this.parentCategories[i];
          if (availableCategories.length > 0) {
            for (let i = availableCategories.length - 1; i >= 0; i--) {
              const cat = availableCategories[i];
              let currentCategory = this.cs.findCategory(cat.$key);
              if (!currentCategory) {
                currentCategory = new Category(
                  cat.$key,
                  cat.name,
                  cat.icon,
                  cat.name,
                  [],
                  categoryZIndex,
                  cat.point, parent, undefined, undefined, undefined, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, cat.temporary
                );
                this.cs.addCategory(currentCategory);
                parent.addCategory(currentCategory);
              }
              let layerListPosition = 0;
              for (const layer in cat.layers) {
                if (cat.layers.hasOwnProperty(layer)) {
                  this.getLayerInformation(currentCategory, layer, layerZIndex, layerListPosition);
                  layerZIndex += 1;
                  layerListPosition += 1;
                }
              }
              categoryZIndex += 1;
              //setTimeout(() => this.disableCategory(currentCategory), 500);
            }
            this.categories = this.cs.availableCategories;
          } /*else {
            this.categories = [];
          }*/
        });
      }
      this.isLoading = false;
    } else {
      this.af.list('/' + endpoint + '/').subscribe(availableCategories => {
        if (availableCategories.length > 0) {
          for (let i = availableCategories.length - 1; i >= 0; i--) {
            const cat = availableCategories[i];
            if (cat.layers !== undefined && cat.layers.length !== 0) {
              let currentCategory = this.cs.findCategory(cat.$key);

              if (!currentCategory) {
                currentCategory = new Category(
                  cat.$key,
                  cat.name,
                  cat.icon,
                  cat.description,
                  [],
                  categoryZIndex,
                  cat.bounds, undefined, undefined, undefined, cat.group, 0, cat.disabled
                );
                this.cs.addCategory(currentCategory);
              }
              let layerListPosition = 0;
              for (const layer in cat.layers) {
                if (cat.layers.hasOwnProperty(layer)) {
                  this.getLayerInformation(currentCategory, layer, layerZIndex, layerListPosition);
                  layerZIndex += 1;
                  layerListPosition += 1;
                }
              }
              //setTimeout(() => this.disableCategory(currentCategory), 1000);
              if (endpoint === 'ssi') {
                this.af.object('/ssi/' + cat.$key + '/ssi_value').subscribe(ssiValues => {
                  if (this.ls.getCurrentDate === undefined) {
                    this.ls.setCurrentDatetime = this.getClosestDateToToday(Object.keys(ssiValues));
                  }
                  const ssi = ssiValues[moment.utc(this.ls.getCurrentDateTime).format('YYYYMMDDTHHmmss')];
                  this.ssiValuesList[cat.name] = ssi;
                  this.af.object('/harbours/' + cat.$key).subscribe(harbourInfo => {
                    this.addSSIMapMarker(cat.description, moment.utc(this.ls.getCurrentDateTime).format('YYYYMMDDTHHmmss'),
                      ssi, harbourInfo.lat, harbourInfo.lon);
                    this.ms.addMapPath(harbourInfo.sailing_direction);
                  });
                });
              }
            } else if (endpoint === 'receivers') {
              let currentCategory = this.cs.findCategory(cat.$key);
              if (!currentCategory) {
                currentCategory = new Category(
                  cat.$key,
                  cat.name,
                  cat.icon,
                  cat.name,
                  [],
                  categoryZIndex,
                  cat.points,
                  undefined,
                  undefined,
                  cat.listPosition,
                  undefined,
                  0
                );
                if (cat.points !== undefined) {
                  const layerService = this.ls;
                  let layerListPosition = 0;
                  for (const currentPoint of cat.points) {
                    let newLayer = this.ls.findLayer(currentPoint.receiver);
                    if (!newLayer) {
                      newLayer = new Layer(
                        currentPoint.receiver,
                        currentPoint.receiver,
                        currentPoint.receiver,
                        currentPoint.receiver, currentPoint.receiver, null, null,
                        null, null, null, null,
                        null, null, null, currentPoint.point,
                        false, false, 1.0, layerZIndex, true, currentCategory, layerListPosition, null,
                        undefined, undefined, undefined, undefined, false);

                      //}
                      layerZIndex += 1;
                      layerListPosition += 1;
                      layerService.addLayer(newLayer);
                    }
                    currentCategory.addLayer(newLayer);
                  }
                }

                this.cs.addCategory(currentCategory);
              }
            }
            categoryZIndex += 1;
          }
          this.categories = this.cs.availableCategories;
          this.categories.sort((cat1, cat2) => cat1.listPosition - cat2.listPosition)
        } else {
          this.categories = [];
        }
        this.isLoading = false;
      });
    }
  }

  getDatasetInformation() {
    this.af.object('/dataset_layers/').subscribe(layersList => {
      for (const layer in layersList.layers) {
        if (layersList.layers.hasOwnProperty(layer)) {
          this.getDatasetLayers(layer);
        }
      }
    });
  }

  getDatasetLayers(layer: string) {
    this.af.object('/dataset_layers/layers/' + layer).subscribe(layerDetails => {
      if (layerDetails.$exists()) {
        let newLayer = this.ls.findLayer(layerDetails.$key);
        if (!newLayer) {
          newLayer = new Layer(
            layerDetails.$key,
            layerDetails.$key,
            layerDetails.name,
            layerDetails.name, undefined, undefined, undefined,
            undefined, undefined, undefined, undefined,
            undefined, null, undefined, layerDetails.point, false,
            false, 1.0, undefined, null, undefined, undefined, undefined, undefined, undefined, undefined,
            undefined, true, true);
          this.ls.addLayer(newLayer);
        }
      }
      this.isLoading = false;
    });
  }

  getLayerInformation(parentCategory: Category, layer: string, zIndex: number, listPosition: number) {
    this.af.object('/layers/' + layer).subscribe(layerDetails => {
      if (layerDetails.$exists()) {
        let newLayer = this.ls.findLayer(layerDetails.parent);
        if (!newLayer) {
          newLayer = new Layer(
            layerDetails.parent,
            layerDetails.$key,
            layerDetails.verbose,
            layerDetails.label, layerDetails.label, layerDetails.palettes, layerDetails.supportedStyles,
            layerDetails.defaultStyle, layerDetails.defaultPalette, layerDetails.datesWithData, layerDetails.nearestTimeIso,
            layerDetails.units, layerDetails.scaleRange, layerDetails.scaleRange, layerDetails.bbox, false,
            false, 1.0, zIndex, null, parentCategory, listPosition, layerDetails.tooltip, undefined, undefined, this.layerType,
            this.satelliteType, true);
          this.ls.addLayer(newLayer);
        }
        parentCategory.addLayer(newLayer);
      }
      this.isLoading = false;
    });
  }

  // Layers without categories
  getSoloLayerInformation(layer: string, zIndex: number, listPosition: number) {
    this.isLoading = true;
    this.af.object('/layers/' + layer).subscribe(layerDetails => {
      if (layerDetails.$exists()) {
        let newLayer = this.ls.findLayer(layerDetails.parent);
        if (!newLayer) {
          newLayer = new Layer(
            layerDetails.parent,
            layerDetails.$key,
            layerDetails.verbose,
            layerDetails.label, layerDetails.label, layerDetails.palettes, layerDetails.supportedStyles,
            layerDetails.defaultStyle, layerDetails.defaultPalette, layerDetails.datesWithData, layerDetails.nearestTimeIso,
            layerDetails.units, layerDetails.scaleRange, layerDetails.scaleRange, layerDetails.bbox, false,
            false, 1.0, zIndex, null, null, listPosition, layerDetails.tooltip, undefined, undefined, this.layerType,
            this.satelliteType, true);
          this.ls.addLayer(newLayer);
        }
        this.coastnetIndicatorLayers.unshift(newLayer);
      }
      this.isLoading = false;
    });
  }

  getClosestDateToToday(dateList: any[]): string {
    const today = moment.utc();
    let currentDate: any;
    for (let i = dateList.length - 1; i >= 0; i--) {
      const ssiDate = dateList[i];
      if (currentDate === undefined) {
        if (today.isAfter(ssiDate)) {
          return ssiDate;
        } else {
          currentDate = moment.utc(ssiDate);
        }
      } else {
        if (today.isBefore(ssiDate) && currentDate.isAfter(ssiDate)) {
          currentDate = moment.utc(ssiDate);
        } else {
          return currentDate.format('YYYYMMDDTHHmmss');
        }
      }
    }
  }

  toggleList(category: Category) {
    if (category === this.openCategory) {
      if (this.currentViewType === 'dataset') {
        this.automaticToggle(category);
      }
      this.categoryDragEnabled = true;
      this.openCategory = undefined;
      category.currentState = 'closed';
    } else {
      if (this.openCategory) {
        this.openCategory.currentState = 'closed';
      }
      if (this.currentViewType === 'dataset') {
        this.automaticToggle(category);
      }
      this.openCategory = category;
      this.openCategory.currentState = 'open';
      this.categoryDragEnabled = false;
    }
  }

  toggleParentList(category: ParentCategory) {
    if (category === this.openParentCategory) {
      this.categoryDragEnabled = true;
      this.openParentCategory = undefined;
      category.currentState = 'closed';
    } else {
      if (this.openParentCategory) {
        this.openParentCategory.currentState = 'closed';
      }
      this.openParentCategory = category;
      this.openParentCategory.currentState = 'open';
      this.categoryDragEnabled = false;
    }
  }

  toggleChildList(category: Category) {
    if (category.currentState === 'open') {
      category.currentState = 'closed';
    } else {
      category.currentState = 'open';
    }
    this.openChildCategory = category;
    this.categoryDragEnabled = false;
  }

  automaticToggle(category: Category) {
    if (category.biologicalCategory[0] !== undefined) {
      this.toggleChildList(category.biologicalCategory[0])
    }
    if (category.environmentalCategory[0] !== undefined) {
      this.toggleChildList(category.environmentalCategory[0])
    }
  }

  getState(category: Category) {
    if (category === this.openCategory && this.categoryHasLayers(category, this.satelliteType)) {
      return 'open';
    } else {
      return 'closed';
    }
  }

  getChildState(category: Category) {
    if (category.currentState === 'open' || category.biological !== undefined) {
      return 'open';
    } else {
      return 'closed';
    }
  }

  getParentState(category: ParentCategory) {
    if (category === this.openParentCategory) {
      return 'open';
    } else {
      return 'closed';
    }
  }

  categoryHasLayers(category: Category, satelliteType: string) {
    let nrtNum = 0;
    let timeSeriesNum = 0;
    for (const layer of category.layers) {
      if (satelliteType === 'Near Real Time' && layer.id.lastIndexOf('nrt', 0) === 0) {
        nrtNum++;
      } else if (satelliteType === 'Time Series' && layer.id.lastIndexOf('nrt', 0) === -1) {
        timeSeriesNum++;
      }
    }
    if (nrtNum > 0 || timeSeriesNum > 0 || this.currentViewType === 'dataset' || this.currentViewType === 'insitu' || this.currentViewType === 'receivers') {
      return true;
    } else {
      return false;
    }
  }

  viewLayerSettings(layer: Layer) {
    if (this.ls.getSettingsLayer !== layer) {
      this.ls.setSettingsLayer = layer;
      this.ls.emitSettingsLayer();
    }
    this.dialog.open(LayerSettingsComponent, {
      width: '400px'
    });
  }

  setDatasetMarkers(checked: boolean, category: Category) {
    const categoryName = category.name.replace(' ', '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (category.parentCategory === this.openParentCategory) {
      if (checked) {
        for (const layer of this.ls.getLayers) {
          if (layer.isDatasetLayer) {
            const estuaryName = layer.id.split('_')[1];
            const productName = layer.id.split('_')[0];
            if (estuaryName === categoryName && category.parentCategory.extraId === productName) {
              this.ga.trackEvent("View layer", "Layer", estuaryName+"|"+productName, layer.id);
              layer.isVisible = checked;
              this.ms.addMapMarker(layer.bbox[0], layer.bbox[1], undefined, false, layer, layer.name, productName, estuaryName);
              category.bounds.push(layer.bbox[0]);
              category.bounds.push(layer.bbox[1]);
              category.bounds.push(layer.bbox[0]);
              category.bounds.push(layer.bbox[1]);
              layer.category = category;
              if (layer.isMasterCandidate && this.ls.getVisibleLayers.length === 1 ||
                this.ls.getVisibleLayers.length - (this.ls.getVisibleLayers.filter(lyr => lyr.isMasterCandidate)).length !== 0) {
                this.ls.defineMasterLayer();
              } else {
                if (this.ls.getMasterLayer && this.ls.getMasterLayer.leafletSource instanceof Object) {
                  setTimeout(() => this.ls.getMasterLayer.leafletSource.bringToFront(), 100);
                }
              }
              category.numVisibleLayers += 1;
              category.parentCategory.numVisibleLayers += 1;
            }
          }
        }
      } else {
        if (category.bounds.length > 2) {
          category.bounds.pop();
          category.bounds.pop();
          category.bounds.pop();
          category.bounds.pop();
        }
        for (const layer of this.ls.getLayers) {
          if (layer.isDatasetLayer) {
            const estuaryName = layer.id.split('_')[1];
            const productName = layer.id.split('_')[0];
            if (estuaryName === categoryName && category.parentCategory.extraId === productName) {
              layer.isVisible = checked;
              this.ms.removeMarker(layer);

              category.numVisibleLayers -= 1;
              category.parentCategory.numVisibleLayers -= 1;
              if (category.numVisibleLayers < 0) {
                category.numVisibleLayers = 0;
              }
              //this.ls.defineMasterLayer();
            }
          }
        } this.ls.defineMasterLayer();
      }
    }
    category.hasVisibleLayers = category.numVisibleLayers > 0;
  }

  setVisibility(checked: boolean, layer: Layer, category?: Category, parentCategory?: ParentCategory) {
    layer.isVisible = checked;
    if (this.currentViewType === 'insitu') {
      if (checked) {
        this.ga.trackEvent("View layer", "Layer", layer.verboseId, layer.id);
        this.ls.setCurrentDatetime = layer.nearestTime;
        if (category.numVisibleLayers === 0) {
          this.ms.addMapMarker(category.bounds[0], category.bounds[1], '1', false, layer);
          category.bounds.push(category.bounds[0]);
          category.bounds.push(category.bounds[1]);
          //TEMPORARY UNTIL TEJO BOUNDS FIXED
          if (category.temporaryCoords) {
            category.temporaryCoords.push(category.temporaryCoords[0]);
            category.temporaryCoords.push(category.temporaryCoords[1]);
          }
        }
        category.numVisibleLayers++;
        parentCategory.numVisibleLayers++;
      } else {
        if (category.numVisibleLayers > 0) {
          category.numVisibleLayers--;
          if (parentCategory !== undefined) {
            parentCategory.numVisibleLayers--;
          }
        }
        if (category.bounds.length > 2) {
          category.bounds.pop();
          category.bounds.pop();
          //TEMPORARY UNTIL BOUNDS FIXED TEJO
          if (category.temporaryCoords) {
            category.temporaryCoords.pop();
            category.temporaryCoords.pop();
          }
        }
        this.ms.removeMarker(layer);
      }
      this.ls.emitLayerVisibilityChanged(layer)
    } else if (this.currentViewType === 'receivers') {
      if (checked) {
        this.ga.trackEvent("View layer", "Layer", "telemetry receivers", layer.id);
        this.ls.setCurrentDatetime = layer.nearestTime;
        this.ms.addMapCircle(layer.bbox[0], layer.bbox[1], 500, false, layer, layer.name);
        layer.bbox.push(layer.bbox[0]);
        layer.bbox.push(layer.bbox[1]);
      } else {
        this.ms.removeMarker(layer);
      }
    } else {
      this.ms.setLayerVisiblity(layer);
    }
    if (checked) {
      if (layer.isMasterCandidate && this.ls.getVisibleLayers.length === 1 ||
        this.ls.getVisibleLayers.length - (this.ls.getVisibleLayers.filter(lyr => lyr.isMasterCandidate)).length !== 0) {
        this.ls.defineMasterLayer();
      } else {
        if (this.ls.getMasterLayer && this.ls.getMasterLayer.leafletSource instanceof Object) {
          setTimeout(() => this.ls.getMasterLayer.leafletSource.bringToFront(), 100);
        }
      }
      category.numVisibleLayers += 1;
      //layer.category.numVisibleLayers += 1;
    } else {
      category.numVisibleLayers -= 1;
      //layer.category.numVisibleLayers -= 1;
      this.ls.defineMasterLayer();
    }

    category.hasVisibleLayers = category.numVisibleLayers > 0;
  }

  // Temporary solution to refresh the check icons in the layers list. Review later
  updateLayer(category: Category) {
    category.numVisibleLayers = 0;
    for (const entry of category.layers) {
      if (entry.isVisible) {
        category.numVisibleLayers += 1;
      }
    }
  }

  updateParentCategoryCheckMarks(parentCategory: ParentCategory) {
    let totalVisibleLayers = 0;
    let totalLayers = 0;
    let totalDatasets = 0;
    let totalVisibleDatasets = 0;
    for (const category of parentCategory.subCategory) {
      if (category.biologicalCategory !== undefined) {
        totalDatasets += 1;
        if (category.numVisibleLayers > 0) {
          totalVisibleDatasets += 1;
        }
      } else {
        totalLayers += category.layers.length;
        totalVisibleLayers += category.numVisibleLayers;
      }
    }
    if (totalLayers > 0) {
      if (totalLayers !== totalVisibleLayers) {
        return 'done'
      } else {
        return 'done_all'
      }
    } else if (totalDatasets > 0) {
      if (totalDatasets !== totalVisibleDatasets) {
        return 'done'
      } else {
        return 'done_all'
      }
    }

  }

  setCategoryVisibility(checked: boolean, category: Category) {
    for (const layer of category.layers) {
      this.setVisibility(checked, layer);
    }
  }

  sortLayers(layers) {
    return layers.sort(function(lyr1, lyr2){
      if(lyr1.verboseId < lyr2.verboseId) { return -1; }
      if(lyr1.verboseId > lyr2.verboseId) { return 1; }
      return 0;
  });
  }

  layerReorder(newIndex: any, layer: Layer) {
    const oldZIndex = layer.category.layers.length - 1 - layer.listPosition;
    const positionDifference = oldZIndex - newIndex;

    const isHigher = positionDifference > 0;
    let lowerBound: number;
    let upperBound: number;

    if (layer) {
      const startZIndex = layer.zIndex;
      const targetZIndex = layer.zIndex + positionDifference;
      lowerBound = Math.min(startZIndex, targetZIndex);
      upperBound = Math.max(startZIndex, targetZIndex);

      if (isHigher) {
        lowerBound += 1;
      } else {
        upperBound -= 1;
      }

      for (const lyr of layer.category.layers) {
        if (lyr.zIndex >= lowerBound && lyr.zIndex <= upperBound) {
          if (isHigher) {
            lyr.zIndex -= 1;
            lyr.listPosition -= 1;
          } else {
            lyr.zIndex += 1;
            lyr.listPosition += 1;
          }
          this.ls.emitZIndexChanged(lyr);
        }
      }
      layer.zIndex += positionDifference;
      layer.listPosition += positionDifference;

      this.ls.emitZIndexChanged(layer);
      this.ls.defineMasterLayer();

    }
  }

  categoryReorder(newIndex: any, category: Category) {
    const oldIndex = this.categories.length - category.zIndex;
    const positionDifference = oldIndex - newIndex;

    const isHigher = positionDifference > 0;
    let lowerBound: number;
    let upperBound: number;


    if (category) {
      const startZIndex = category.zIndex;
      const targetZIndex = category.zIndex + positionDifference;
      lowerBound = Math.min(startZIndex, targetZIndex);
      upperBound = Math.max(startZIndex, targetZIndex);

      if (isHigher) {
        lowerBound += 1;
      } else {
        upperBound -= 1;
      }

      const layerOffset = category.layers.length;
      let finalOffset = 0;
      this.cs.availableCategories.forEach((thisCategory) => {
        if (thisCategory.zIndex >= lowerBound && thisCategory.zIndex <= upperBound) {
          if (isHigher) {
            thisCategory.zIndex -= 1;
          } else {
            thisCategory.zIndex += 1;
          }

          thisCategory.layers.forEach((layer) => {
            if (isHigher) {
              layer.zIndex -= layerOffset;
              finalOffset += 1;
            } else {
              layer.zIndex += layerOffset;
              finalOffset -= 1;
            }
            this.ls.emitZIndexChanged(layer);
          });
        }
      });

      category.layers.forEach((layer) => {
        layer.zIndex += finalOffset;
        this.ls.emitZIndexChanged(layer);
      });

      category.zIndex = category.zIndex + positionDifference;
      this.ls.defineMasterLayer();
    }
  }

  zoomCategoryExtent(category: Category) {
    if (category.numVisibleLayers === 1) {
      for (const layer of category.layers) {
        if (layer.isVisible) {
          this.ms.zoomToBounds(layer.bbox)
        }
      }
    } else {
      this.ms.zoomToBounds(category.bounds);
    }
  }

  zoomMarkersExtent(category: any) {
    this.ms.zoomToMarkers(category);
  }

  /*zoomMarkerExtent(layer: Layer) {
    this.ms.zoomToMarker(layer);
  }*/

  zoomMarkerExtent(item: any) {
    let bounds
    //TEMPORARY UNTIL BOUNDS ARE FIXED
    if (item.temporaryCoords !== undefined && item.bounds.length > 2) {
      bounds = item.temporaryCoords;
      this.ms.zoomToMarker(bounds);
    } else if (item.bounds !== undefined && item.bounds.length > 2) {
      bounds = item.bounds;
      this.ms.zoomToMarker(bounds);
    } else if (item.bbox !== undefined && item.bbox.length > 2) {
      bounds = item.bbox;
      this.ms.zoomToMarker(bounds)
    }

  }

  fetchSSIValues() {
    if (this.currentViewType === 'ssi' && this.categories.length > 0) {
      this.ms.clearSSIMarkers()
      for (const _category of this.categories) {
        const currentTime = moment.utc(this.ls.getCurrentDateTime).format('YYYYMMDDTHHmmss');
        this.af.object('/ssi/' + _category.id + '/ssi_value').subscribe(ssiValues => {
          if (ssiValues[currentTime] !== undefined) {
            const ssi = ssiValues[currentTime];
            this.ssiValuesList[_category.name] = ssi;
            this.af.object('/harbours/' + _category.id).subscribe(harbourInfo => {
              this.addSSIMapMarker(harbourInfo.name, this.ls.getCurrentDateTime, ssi, harbourInfo.lat, harbourInfo.lon);
            })
          } else {
            this.ssiValuesList[_category.name] = undefined;
          }
        });
      }
    }
  }

  addSSIMapMarker(title: string, currentDate: string, ssiValue: number, lat: number, lon: number) {
    const pinDescription = '<h5 class="simocean-color">' + title + '</h5>' +
      '<p class="white-text"><b>' +
      moment.utc(currentDate).format('YYYY-M-D, HH:mm:ss') + '</b><p>' +
      '<p class="white-text"><b>' + this.ssiDescription[ssiValue - 1] + '</b><p>';
    //this.ms.addMapMarker(lat, lon, '' + ssiValue, false, pinDescription);
  }

  get layerType() {
    if (this.currentViewType === 'forecast') {
      return 'satellite'
    } else {
      return 'sensors'
    }
  }


  unselectParentCategoryLayers(parentCategory: ParentCategory) {
    for (const cat of parentCategory.subCategory) {
      if (cat.biologicalCategory !== undefined) {
        this.setDatasetMarkers(false, cat)
      } else {
        for (const lyr of cat.layers) {
          if (lyr.isVisible) {
            this.setVisibility(false, lyr, cat, parentCategory)
          }
        }
      }

    }
  }

  unselectCategoryLayers(category: Category, parentCategory: ParentCategory) {
    if (this.satelliteType !== undefined) {
      for (const _layer of category.layers) {
        if (_layer.isVisible) {
          this.setVisibility(false, _layer, category)
        }
      }
      this.ms.emitLoading(false);
    } else {
      for (const _layer of category.layers) {
        if (_layer.isVisible) {
          this.setVisibility(false, _layer, category, parentCategory)
        }
      }
    }
  }

  subCategorySelectedLayers(parentCategory: ParentCategory) {
    return parentCategory.subCategory.find(cat => cat.hasVisibleLayers) !== undefined;
  }

  openDocumention(type: string){
    switch (type) {
      case "nrt":
        window.open(environment.NRT_doc);
        this.ga.trackEvent("View docs", "Docs", "NRT documentation", "");
        break;
      case "timeseries":
        window.open(environment.TS_doc);
        this.ga.trackEvent("View docs", "Docs", "TimeSeries documentation", "");
        break
      case "insitu":
        window.open(environment.insitu_doc);
        this.ga.trackEvent("View docs", "Docs", "Insitu documentation", "");
      default:
        break;
    }
  }
}
