import { Component, OnInit, Input } from '@angular/core';
import { MapService } from '../shared/map.service';

declare var $: any;
@Component({
  selector: 'gp-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  isLoading: boolean;
  constructor(private ms: MapService) {
    ms.loadingChanged$.subscribe(
            status => {
                this.isLoading = status;
            }
        );
  }

  ngOnInit() {
  }
}
