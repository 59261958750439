import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MapService, Layer, LayersService, UtilitiesService } from '../shared';
import { DatasetsService } from '../datasets.service';
import { TimelineService } from '../shared/timeline.service';
import { ApiService } from '../shared/api.service';
import { SelectaoiService } from '../shared/selectaoi.service';
import { LatLng } from '../shared/lat-lng-coordinates';
import * as L from 'leaflet';
import { start } from 'repl';
import { TutorialService } from '../shared/tutorial.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';


declare var vis: any;
declare var $: any;
declare var require: any;
declare const moment: any;

@Component({
  selector: 'gp-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})

export class TimelineComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly maxTimesMarkersMethodCalled = 2;

  private masterLayer: Layer;
  private timelineItems = new vis.DataSet();
  private timeline: any;
  private isAnimated = false;
  private selected_format: string;
  private selected_operation: string;
  private boundingBox: LatLng;
  private geojson: any;
  private isCalcDisabled;
  private startTime: any;
  private endTime: any;
  private minDate: any;
  private maxDate: any;
  private termsAccepted: boolean;

  isDrawingEnabled = false;
  isSidenavOpen: boolean;
  isDialogOpen: boolean;

  @Input()
  isAnimationActive;

  @Input()
  showAnimationBar;

  @Input()
  isCalculator;

  @Input()
  isTimelineEnabled;

  frameRate = 10;

  constructor(private layersService: LayersService, private ds: DatasetsService,
    private ms: MapService, private tl: TimelineService, private capi: ApiService,
    private aoiService: SelectaoiService, private tutorial: TutorialService,
    private dialog: MatDialog, private ut: UtilitiesService) {
    this.masterLayer = layersService.getMasterLayer;
    this.isCalcDisabled = this.masterLayer === undefined;
    this.clearAoI();
    this.geojson = undefined;
    layersService.masterLayerChanged$.subscribe(
      newLayer => {
        this.masterLayer = newLayer;
        this.setupTimeline();
        this.removeBBox();
        this.isCalcDisabled = this.masterLayer === undefined;
        this.geojson = undefined;
        this.clearAoI();
        this.addCalcDefaultParams();
        if (this.capi.currentStatus !== 'Processing') {
          this.capi.clear();
        }
      });

    tl.startTodayChanged$.subscribe(() => {
      this.setupTimeline();
    });

    this.ms.animationChanged$.subscribe(
      animationStatus => {
        this.isAnimated = animationStatus;
      }
    );

    this.tl.timeSeriesActivated$.subscribe(
      activatedStatus => {
        if (activatedStatus && this.masterLayer !== undefined) {
          this.addAnimationMarkers();
        } else {
          if (!this.isAnimationActive || !this.isCalculator) {
            this.removeAnimationMarkers();
            this.startTime = undefined;
            this.endTime = undefined;
          }
        }
      }
    )
  }

  ngOnInit() {
    this.createTimeline();
    this.addCalcDefaultParams();
  }

  ngAfterViewInit() {
    if (this.isCalculator && this.masterLayer !== undefined) {
      this.addAnimationMarkers();
    }
  }

  ngOnDestroy() {
    this.ms.removeLayerFromMap('temp');
  }

  addCalcDefaultParams() {
    if (this.masterLayer === undefined) {
      this.startTime = undefined;
      this.endTime = undefined;
    } else {
      if (this.isCalculator) {
        if (!(this.masterLayer.leafletSource instanceof Object)) {
          this.geojson = 'all';
        }
        if (!this.masterLayer.isDatasetLayer) {
          this.selected_operation = 'media';
          this.setDefaultFormat();
        } else {
          this.selected_operation = 'none';
          this.setDefaultFormat();
        }
        if (!this.isCalcDisabled) {
          this.addAnimationMarkers();
          this.clearDrawing();
        }
        this.addBBoxToMap();
      }
      if (!this.masterLayer.isDatasetLayer) {
        this.setMinMaxDates()
      } else {
        this.minDate = undefined;
        this.maxDate = undefined;
      }
    }

  }

  createTimeline() {
    const container = $('#visualization')[0];
    // Configuration for the Timeline
    const options = {
      height: '110px',
      zoomMin: 1000 * 60 * 60 * 2 * 2.5, // 15 minute steps for maximum zoom in.
      itemsAlwaysDraggable: true,
      editable: {
        add: false,
        updateTime: true,
        updateGroup: false,
        remove: false,

      },
      clickToUse: false,
      showMajorLabels: true,
      format: {
        minorLabels: {
          millisecond: 'SSS',
          second: 's',
          minute: 'HH:mm',
          hour: 'HH:mm',
          weekday: 'ddd D/M',
          day: 'MMM D',
          month: 'MMM',
          year: 'YYYY'
        }
      },
      moment: function (date) {
        return vis.moment(date).utc();
      }
    };

    // Create a Timeline
    this.timeline = new vis.Timeline(container, this.timelineItems, options);

    this.timeline.on('rangechanged', (properties) => {
      if (this.masterLayer) {
        const timeDifference = ((properties.end - properties.start) / 1000) / 86400;

        for (const i = properties.start; i <= properties.end; i.setDate(i.getDate() + 1)) {
          let year = "" + i.getFullYear();
          let month = "" + i.getMonth();
          let day = i.getDate();

          if (this.timelineItems.get(year + month + day) === null) {
            for (const _year in this.masterLayer.availableDates) {
              if (year === _year) {
                for (const _month in this.masterLayer.availableDates[_year]) {
                  if (month === _month) {
                    for (const _day of this.masterLayer.availableDates[_year][_month]) {
                      if (day === _day) {
                        const currentDate = moment.utc([_year, +_month, _day]);
                        if (!this.tl.timelineStartToday || moment.utc().isSameOrBefore(currentDate, 'day')) {
                          this.timelineItems.add([{
                            id: year + month + day,
                            start: moment.utc([year, +month, day, 0, 0, '01']),
                            end: moment.utc([year, +month, day, 23, 59, 59]),
                            type: 'background'
                          }]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if ((timeDifference <= 13 && this.masterLayer.leafletSource !== null) || (timeDifference <= 0.5 && this.masterLayer.leafletSource === null)) {
            const currentDate = i.getFullYear() + '-' + ('0' + (i.getMonth() + 1)).slice(-2) + '-' +
              ('0' + i.getDate()).slice(-2);
            this.ds.getTimesteps(this.masterLayer.id, currentDate).subscribe(
              (timesteps: any) => {
                for (const timestep of timesteps) {
                  if (this.timelineItems.get(currentDate + 'T' + timestep) === null) {
                    const timestepDate = moment.utc(currentDate + 'T' + timestep);
                    if (!this.tl.timelineStartToday || moment.utc().isSameOrBefore(timestepDate, 'day')) {
                      this.timelineItems.add({
                        id: currentDate + 'T' + timestep,
                        start: currentDate + ' ' + timestep,
                        type: 'point',
                        editable: false
                      });
                    }
                  }
                }
              }
            );
          } else {
            this.removeAllTimestamps();
          }
        }
      }
    });

    this.timeline.on('select', (properties) => {
      if (this.layersService.getMasterLayer.leafletSource !== null) {
        if (typeof (properties.items[0]) !== 'undefined' && properties.items[0] !== 'animation-start' &&
          properties.items[0] !== 'animation-end') {
          try {
            this.timeline.removeCustomTime('timeline');
            this.timeline.addCustomTime(properties.items[0].replace('T', ' '), 'timeline');
          } catch (err) {
            this.timeline.addCustomTime(properties.items[0].replace('T', ' '), 'timeline');
          }
          this.layersService.setCurrentDate = properties.items[0].split('T')[0];
          this.layersService.setCurrentTime = properties.items[0].split('T')[1];
          this.layersService.emitChangedTime();
        }
      }
    });

    this.setupTimeline();
    //this.onAnimationEnable();
  }

  setupTimeline() {
    if (this.masterLayer && this.masterLayer.availableDates !== undefined) {
      const yearKeys = Object.keys(this.masterLayer.availableDates);
      let startDate = moment();
      let startYear, startMonth, startDay;
      if (!this.tl.timelineStartToday) {
        startYear = yearKeys[0];
        startMonth = Object.keys(this.masterLayer.availableDates[startYear])[0];
        startDay = this.masterLayer.availableDates[startYear][startMonth][0];
        startDate = moment([startYear, startMonth, startDay]);
      }
      const timelineStart = startDate;
      timelineStart.subtract(3, 'day');

      const endYear = yearKeys[yearKeys.length - 1];
      const endMonthKeys = Object.keys(this.masterLayer.availableDates[endYear]);
      const endMonth = endMonthKeys[endMonthKeys.length - 1];
      const daysList = this.masterLayer.availableDates[endYear][endMonth];

      const endDay = daysList[daysList.length - 1];

      const endDate = moment([endYear, endMonth, endDay]);
      const timelineEnd = endDate;
      timelineEnd.add(3, 'day');

      let timelineStartWms;
      if (daysList.length < 8) {
        timelineStartWms = moment([endYear, endMonthKeys[endMonthKeys.length - 2], endDay]);
      } else {
        timelineStartWms = moment([endYear, endMonth, endDay - 5]);
      }

      this.removeAllDates();
      if (this.masterLayer.leafletSource === null) {
        this.timeline.setOptions({
          start: timelineStart,
          min: timelineStart,
          end: timelineEnd,
          max: timelineEnd
        });
      } else {
        this.timeline.setOptions({
          start: timelineStartWms,
          min: timelineStart,
          end: timelineEnd,
          max: timelineEnd
        });
      }

      if (this.layersService.getCurrentDate && this.layersService.getCurrentTime) {
        try {
          this.timeline.removeCustomTime('timeline');
          this.timeline.addCustomTime(this.layersService.getCurrentDateTime, 'timeline');
        } catch (err) {
          this.timeline.addCustomTime(this.layersService.getCurrentDateTime, 'timeline');
        }
        if (this.isAnimationActive) {
          setTimeout(() => this.addAnimationMarkers(), 300);
        } else {
          setTimeout(() => this.addAnimationMarkers(), 300);
          this.removeAnimationMarkers();
        }
        setTimeout(() => this.setCurrentWindow(), 150);
      }
    } else {
      this.removeAllTimelineElements();
    }
  }

  setMinMaxDates() {
    const _years = this.getDateParameter(undefined, undefined)
    const minYear = Math.min(..._years)
    const maxYear = Math.max(..._years)

    const minYearMonths = this.getDateParameter(minYear, undefined)
    const maxYearMonths = this.getDateParameter(maxYear, undefined)
    const minMonth = Math.min(...minYearMonths)
    const maxMonth = Math.max(...maxYearMonths)

    const minMonthDays = this.getDateParameter(minYear, minMonth);
    const maxMonthDays = this.getDateParameter(maxYear, maxMonth)
    const minDay = Math.min(...minMonthDays)
    const maxDay = Math.max(...maxMonthDays)

    this.minDate = new Date(minYear, minMonth, minDay - 1)
    this.maxDate = new Date(maxYear, maxMonth, maxDay + 1)
  }

  getDateParameter(targetYear: number, targetMonth: number) {
    if (targetYear === undefined && targetMonth === undefined) {
      const _years: number[] = [];
      for (const _year in this.masterLayer.availableDates) {
        if (this.masterLayer.availableDates.hasOwnProperty(_year)) {
          _years.push(parseInt(_year))
        }
      }
      return _years;
    } else if (targetYear !== undefined && targetMonth === undefined) {
      const _months: number[] = [];
      for (const _month in this.masterLayer.availableDates[targetYear]) {
        if (this.masterLayer.availableDates[targetYear].hasOwnProperty(_month)) {
          _months.push(parseInt(_month))
        }
      }
      return _months;
    } else {
      const _days: number[] = [];
      for (const _day in this.masterLayer.availableDates[targetYear][targetMonth]) {
        if (this.masterLayer.availableDates[targetYear][targetMonth].hasOwnProperty(_day)) {
          _days.push(this.masterLayer.availableDates[targetYear][targetMonth][_day])
        }
      }
      return _days;
    }
  }

  zoomIn() {
    this.timeline.zoomIn(0.5);
  }

  zoomOut() {
    this.timeline.zoomOut(0.5);
  }

  setCurrentWindow() {
    if (this.layersService.getCurrentDateTime !== undefined) {
      this.timeline.setWindow(moment(this.layersService.getCurrentDateTime).subtract(1, 'day').toDate(),
        moment(this.layersService.getCurrentDateTime).add(6, 'day').toDate());
    }
  }

  removeAllDates() {
    this.timelineItems.forEach((it) => {
      this.timelineItems.remove(it.id);
    });
  }

  removeAllTimestamps() {
    this.timelineItems.forEach((it) => {
      if (it.type !== 'background' && it.id !== 'animation-start' && it.id !== 'animation-end') {
        this.timelineItems.remove(it.id);
      }
    });
  }

  removeAllTimelineElements() {
    this.timelineItems.forEach((it) => {
      this.timelineItems.remove(it.id);
    });

    try {
      this.timeline.removeCustomTime('timeline');
    } catch (err) {
      //console.log('No custom time in timeline');
    }
  }

  onAnimationEnable() {
    if (this.isAnimationActive) {
      this.addAnimationMarkers();
    } else {
      this.removeAnimationMarkers();
    }
  }

  addAnimationMarkers(view_only: boolean = false) {
    // let startTime, endTime;
    this.removeAnimationMarkers();
    this.startTime = undefined
    this.endTime = undefined
    if (this.masterLayer !== undefined && this.masterLayer.leafletSource !== null) {
      this.startTime = moment.utc(this.layersService.getCurrentDateTime).subtract(5, 'days').startOf('day').toDate();
      this.endTime = moment.utc(this.layersService.getCurrentDateTime).subtract(0, 'days').startOf('day').toDate();
      if (!this.isCalculator) {
        this.startTime = moment.utc(this.layersService.getCurrentDateTime).subtract(5, 'days').startOf('day').toDate();
        this.endTime = moment.utc(this.layersService.getCurrentDateTime).subtract(0, 'days').startOf('day');
        // this.startTime = moment.min(this.startTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate(); //mexi aqui de moment.max para moment.min
        this.endTime = moment.max(this.endTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();
      }
    } else if (this.masterLayer.id.startsWith("ts_")) {
      try {
        this.startTime = moment.utc(this.layersService.getCurrentDateTime).subtract(6, 'days').startOf('day').toDate();
      } catch (TypeError) {
        this.startTime = moment.utc(this.layersService.getCurrentDateTime).subtract(6, 'days').startOf('day')
        this.startTime = moment.max(this.startTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();
      }
      try {
        this.endTime = moment.utc(this.layersService.getCurrentDateTime).add(1, 'days').startOf('day').toDate();
      } catch (TypeError) {
        this.endTime = moment.utc(this.layersService.getCurrentDateTime).add(1, 'days').startOf('day')
        this.endTime = moment.max(this.endTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();
      }

      //startTime = moment.max(startTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();
      //endTime = moment.max(endTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();

    } else {
      try {
        this.startTime = moment.utc(this.layersService.getCurrentDateTime).subtract(2, 'days').startOf('day').toDate();
      } catch (TypeError) {
        this.startTime = moment.utc(this.layersService.getCurrentDateTime).subtract(2, 'days').startOf('day')
        this.startTime = moment.max(this.startTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();
      }
      try {
        this.endTime = moment.utc(this.layersService.getCurrentDateTime).add(1, 'days').startOf('day').toDate();
      } catch (TypeError) {
        this.endTime = moment.utc(this.layersService.getCurrentDateTime).add(1, 'days').startOf('day')
        this.endTime = moment.max(this.endTime, moment.utc(this.timeline.getItemRange().min).add(1, 'day').startOf('day')).toDate();
      }

    }
    try {
      this.timelineItems.add({
        id: 'animation-start',
        content: 'Start',
        start: this.startTime,
        style: 'background-color: rgb(66, 66, 66); color: #ff9100; border: none;'
      });
      this.timelineItems.add({
        id: 'animation-end',
        content: 'End',
        start: this.endTime,
        style: 'background-color: rgb(66, 66, 66); color: #ff9100; border: none;'
      });
      const tempStartDate = new Date();
      const tempEndDate = new Date();
      if (this.isCalculator) {
        setTimeout(() => {
          if (this.startTime !== undefined && this.endTime !== undefined) {
            this.timeline.setWindow(tempStartDate.setDate(this.startTime.getDate() - 2), tempEndDate.setDate(this.endTime.getDate() + 2))
          }
        }, 150);
      } else {
        setTimeout(() => {
          if (this.startTime !== undefined && this.endTime !== undefined) {
            this.timeline.setWindow(tempStartDate.setDate(this.startTime.getDate() - 2), tempEndDate.setDate(this.endTime.getDate() + 2))
          }
        }, 100);
      }

      setTimeout(() => {
        if (this.startTime !== undefined && this.endTime !== undefined) {
          this.tl.start = this.startTime
          this.tl.end = this.endTime;
        }
      }, 300);
        this.timelineItems.on('update', (_, props) => {
          props.data.forEach(elem => {
            if (elem.id === 'animation-start') {
              if (elem.start > this.endTime) {
                this.timelineItems.update({ id: elem.id, start: this.startTime })
              } else {
                this.tl.start = moment.utc(elem.start).toDate();
                this.startTime = moment.utc(elem.start).toDate();
              }
            } else if (elem.id === 'animation-end') {
              if (elem.start < this.startTime) {
                this.timelineItems.update({ id: elem.id, start: this.endTime })
              } else {
                this.tl.end = moment.utc(elem.start).toDate();
                this.endTime = moment.utc(elem.start).toDate();
              }
            }
          });
        });
    } catch (err) {
      console.log('Animation marker already exists');
      if (!this.isCalculator && !this.isAnimationActive) {
        const tempStartDate = new Date();
        const tempEndDate = new Date();
        setTimeout(() => this.timeline.setWindow(tempStartDate.setDate(this.startTime.getDate() - 2),
          tempEndDate.setDate(this.endTime.getDate() + 2)), 150);
      }
    }
  }

  removeAnimationMarkers() {
    this.timelineItems.remove('animation-start');
    this.timelineItems.remove('animation-end');
  }

  get animationText() {
    if (this.isAnimated) {
      return 'STOP'
    } else {
      return 'START'
    }
  }

  get animationIcon() {
    if (this.isAnimated) {
      return 'stop'
    } else {
      return 'play_arrow'
    }
  }

  get isPlayDisabled() {
    if (this.isAnimationActive && !this.ms.isAnimationLoading) {
      return false
    } else {
      return true
    }
  }
  toggleAnimation() {
    if (this.isAnimated) {
      this.stopLayerAnimation();
      this.isAnimated = false;
    } else {
      this.animateLayer();
      this.isAnimated = true;
    }
  }

  animateLayer() {
    let startTime: string;
    let endTime: string;
    this.timelineItems.forEach((it) => {
      if (it.id === 'animation-start') {
        startTime = moment(it.start).format('YYYY-MM-DDTHH:mm:ss.000[Z]');
      }
      if (it.id === 'animation-end') {
        endTime = moment(it.start).format('YYYY-MM-DDTHH:mm:ss.000[Z]');
      }
    });

    const status = this.ms.addAnimatedLayer(this.masterLayer, startTime + '/' + endTime, '' + this.frameRate, 0);
    if (status !== undefined) {
      this.toggleAnimation();
    }
  }

  stopLayerAnimation() {
    this.ms.removeAnimatedLayer();
  }

  get masterType() {
    if (this.masterLayer !== undefined) {
      if (this.masterLayer.isDatasetLayer) {
        return 'Dataset'
      } else {
        return this.masterLayer.type;
      }
    } else {
      return '--';
    }
  }

  get masterProductType() {
    if (this.masterLayer !== undefined) {
      if (this.masterLayer.leafletSource instanceof Object) {
        return (this.masterLayer.id.lastIndexOf('nrt', 0) === -1 ? 'historic' : 'nrt');
      } else if (this.masterLayer.isDatasetLayer) {
        return 'historic';
      } else if (this.masterLayer.leafletSource === null) {
        return (this.masterLayer.id.lastIndexOf('ts', 0) === -1 ? 'insitu' : 'insituTimeSeries');
      } else {
        return 'insitu';
      }
    } else {
      return '--';
    }
  }

  get masterProduct() {
    if (this.masterLayer !== undefined) {
      if (this.masterLayer.isDatasetLayer) {
        return this.masterLayer.category.name + ' - ' + this.masterLayer.id.split('_')[0];
      } else if (!(this.masterLayer.leafletSource instanceof Object)) {
        return this.masterLayer.category.parentCategory.name + ' - ' + this.masterLayer.category.name +
          ' - ' + this.masterLayer.id.split('/').pop();
      } else {
        return this.masterLayer.verboseId;
      }
    } else {
      return '--';
    }
  }

  invokeAPI() {
    const startDate = this.startTime.toLocaleDateString('en-GB').replace(/\//g, '-');
    const endDate = this.endTime.toLocaleDateString('en-GB').replace(/\//g, '-');
    this.isDialogOpen = true
    const dialog = this.dialog.open(DisclaimerComponent);

    dialog.afterClosed().subscribe((flag) => {
      this.isDialogOpen = false;
      if (flag) {
        this.capi.invokeProcessor(this.masterType, this.masterProductType, this.masterLayer.id,
          startDate, endDate, this.geojson, this.selected_operation, this.selected_format);
      } else {
        console.log('err')
      }
    })

  }

  selectAoI() {
    // We call this method so that the bounding box stored in the session
    // storage is automatically added to the map
    this.ms.drawingChangedSource.next([true, 1])
    this.setupMapServiceEvents();
    this.prepareDrawing();
    this.ms.drawBox();
    setTimeout(() => {
      this.ms.setDrawing(false)
    }, 2000);
  }

  addBBoxToMap() {
    if (this.boundingBox) {
      this.ms.addPolygonLayer('temp', this.boundingBox, true, '#76FF03');
      this.aoiService.setBoundingBox(this.boundingBox);
    }
  }

  setupMapServiceEvents() {
    // When the user ends drawing, set the bounding box coordinates
    this.ms.addDrawEvent('editable:drawing:commit', shape => {
      // We need to check if the polygon is valid for a search: it must have more than
      // 2 points
      if (this.setBboxFromShape(shape.layer._latlngs[0])) {
        this.ms.removeLeafletLayerFromMap(shape.layer);
        const poly = this.ms.addPolygonLayer('temp', this.boundingBox, true, '#76FF03');
        this.aoiService.setBoundingBox(this.boundingBox);
        this.createGeoJson(poly);
        this.ms.drawingChangedSource.next([false, 1])
      }
      this.isDrawingEnabled = false;
      this.tl.aoiDrawingEnabled = false;
      //this.mapService.updateTooltip(false);
    });
  }

  setBboxFromShape(latLngs: any) {
    if (this.boundingBox !== latLngs && latLngs.length >= 3) {
      this.boundingBox = latLngs;
      this.aoiService.setBoundingBox(this.boundingBox);
      return true;
    }
    return false;
  }

  prepareDrawing() {
    // If the drawing is enabled, we remove the temporary layer from the map
    // to start a new drawing
    if (this.isDrawingEnabled) {
      this.clearDrawing();
    }
    this.isDrawingEnabled = true;
    this.tl.aoiDrawingEnabled = true;
    this.ms.setDrawing(true)
  }

  clearDrawing() {
    this.ms.commitDrawing();
    this.ms.removeLayerFromMap('temp');
  }

  clearAoI() {
    this.clearDrawing();
    this.aoiService.clearBoundingBox();
    this.boundingBox = this.aoiService.getBoundingBox[0];
  }

  selectAllArea() {
    this.clearAoI();
    if (this.geojson === 'all') {
      this.geojson = undefined;
    } else {
      this.geojson = 'all';
    }
  }

  get isProcessingReady() {
    return this.layersService.getMasterLayer !== undefined && this.isDateRangeValid
      && (this.geojson === 'all' || this.aoiService.isAoIValid()) && !this.operationNotAllowed
  }

  get isDateRangeValid() {
    return this.tl.start < this.tl.end;
  }

  get status() {
    return this.capi.currentStatus;
  }

  get message() {
    return this.capi.responseMessage;
  }

  get downloadUrl() {
    return this.capi.result;
  }

  get availableOperations() {
    const operations = Object.keys(this.capi.allowedOperations)
    if (this.masterLayer !== undefined) {
      if (this.masterLayer.leafletSource instanceof Object) {
        return operations;
      } else if (this.masterLayer.isDatasetLayer) {
        return [operations.find(_key => _key === 'none')];
      } else {
        return operations;
      }
    } else {
      return operations;
    }
  }

  getOperationName(opKey: string) {
    return this.capi.allowedOperations[opKey];
  }

  get availableFormats() {
    if (this.selected_operation !== undefined) {
      return Object.keys(this.capi.getAllowedOutputFormats(this.selected_operation, this.masterLayer))
    } else {
      return []
    }
  }

  getFormatName(formKey: string) {
    if (this.selected_operation !== undefined) {
      return this.capi.getAllowedOutputFormats(this.selected_operation, this.masterLayer)[formKey]
    } else {
      return undefined
    }
  }

  get operationNotAllowed() {
    return this.masterLayer.isDatasetLayer && this.selected_operation !== 'none';
  }

  setDefaultFormat() {
    this.selected_format = this.availableFormats[0]
  }

  removeBBox() {
    this.boundingBox = undefined;
  }

  createGeoJson(polygon: any) {
    // const coords = polygon.getLatLngs();
    // let coords_aux = coords[0].slice()
    // let idx = 0;
    // for (const latlng of coords[0]) {
    //   const oldLat = latlng.lat;
    //   const oldLng = latlng.lng;
    //   coords_aux[idx].lat = oldLng;
    //   coords_aux[idx].lng = oldLat;
    //   idx += 1;
    // }
    // polygon.setLatLngs(coords_aux);
    this.geojson = polygon.toGeoJSON();
  }

  changeMarker(markerId: boolean, event: any) {
    if (markerId) {
      this.timelineItems.update({ id: 'animation-end', start: event.value })
      this.tl.end = event.value;
      this.timeline.setWindow(this.tl.start.setDate(this.tl.start.getDate() - 2), event.value.setDate(event.value.getDate() + 2))
    } else {
      this.timelineItems.update({ id: 'animation-start', start: event.value })
      this.tl.start = event.value;
      this.timeline.setWindow(event.value.setDate(event.value.getDate() - 2), this.tl.end.setDate(this.tl.end.getDate() + 2))
    }
  }

  get timeSeriesOpen() {
    return this.ms.timeSeriesOpen;
  }

  get isAnimationEnabled() {
    return this.masterLayer && (this.masterLayer.leafletSource instanceof Object)
  }

  openTermsDialog() {
    this.dialog.open(DisclaimerComponent)
  }

}
