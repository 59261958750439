import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { GoogleAnalyticsService } from '../../shared/google-analytics.service';

@Component({
  selector: 'gp-user-policy',
  templateUrl: './user-policy.component.html',
  styleUrls: ['./user-policy.component.css']
})
export class UserPolicyComponent implements OnInit {

  private userAccepted: boolean;

  constructor(private ga: GoogleAnalyticsService, public dialogRef: MatDialogRef<UserPolicyComponent>) {
    this.userAccepted = false;
   }

  ngOnInit() {
  }

  checkUserPolicy(event: any) {
    this.userAccepted = event['checked'];
  }

  applyPolicy(){
    if (this.userAccepted) {
      localStorage.setItem('userAccepted', "true");
      this.ga.startTracking();
    } else {
      localStorage.setItem('userAccepted', "false")
    }
  }



}
