import { Component, OnInit, Input } from '@angular/core';
import { Layer, LayersService } from '../../shared';

@Component({
  selector: '[gp-layer]',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.css']
})
export class LayerComponent implements OnInit {
  @Input() layer: Layer;
  constructor() { }

  ngOnInit() {
  }

}
