import { Injectable } from '@angular/core';
import { Layer } from './layer';
import { Subject } from 'rxjs/Subject';
import { DatasetsService } from '../datasets.service';
declare var $: any;
declare const moment: any;

@Injectable()
export class LayersService {
  private currentDate: string;
  private currentTime: string;

  private masterLayer: Layer;
  private settingsLayer: Layer;

  private layers: Layer[] = [];

  private masterLayerChangedSource = new Subject<Layer>();
  private settingsLayerChangedSource = new Subject<Layer>();

  private currentDateChangedSource = new Subject<string>();
  private currentTimeChangedSource = new Subject<string>();

  private layerVisibilityChangedSource = new Subject<Layer>();
  private layerResetChangedSource = new Subject<Layer>();
  private layerSelectedSource = new Subject<Layer>();

  private zIndexChangedSource = new Subject<Layer>();


  settingsLayerChanged$ = this.settingsLayerChangedSource.asObservable();
  masterLayerChanged$ = this.masterLayerChangedSource.asObservable();
  currentDateChanged$ = this.currentDateChangedSource.asObservable();
  currentTimeChanged$ = this.currentTimeChangedSource.asObservable();
  layerVisibilityChanged$ = this.layerVisibilityChangedSource.asObservable();
  layerResetChanged$ = this.layerResetChangedSource.asObservable();
  layerSelected$ = this.layerSelectedSource.asObservable();
  zIndexChanged$ = this.zIndexChangedSource.asObservable();

  constructor(private ds: DatasetsService) {
  }

  addLayer(layer: Layer) {
    this.layers.unshift(layer);
  }

  get getLayers(): Layer[] {
    return this.layers;
  }

  get getVisibleLayers(): Layer[] {
    const layers = [];
    for (const lyr of this.layers) {
      if (lyr.isVisible) {
        layers.push(lyr);
      }
    }
    return layers;
  }

  get getVisibleDatasetLayers(): Layer[] {
    const datasetLayers = [];
    for (const lyr of this.getVisibleLayers) {
      if (lyr.isDatasetLayer) {
        datasetLayers.push(lyr);
      }
    }
    return datasetLayers;
  }

  resetLayerVisibility() {
    for (const lyr of this.layers) {
      if (lyr.isVisible) {
        lyr.isVisible = false;
        this.layerResetChangedSource.next(lyr);
      }
    }
    this.masterLayer = undefined;
    this.emitMasterLayer();
  }

  defineMasterLayer() {
    let masterLayerCandidate: Layer;
    for (const lyr of this.layers) {
      if (lyr.isVisible && lyr.isMasterCandidate) {
        if (!masterLayerCandidate) {
          masterLayerCandidate = lyr;
          if (!masterLayerCandidate.isDatasetLayer) {
            this.setCurrentDatetime = masterLayerCandidate.nearestTime;
          }
        } else if (lyr.zIndex > masterLayerCandidate.zIndex) {
          masterLayerCandidate = lyr;
          this.setCurrentDatetime = masterLayerCandidate.nearestTime;
        }
      }
    }
    if (masterLayerCandidate !== this.masterLayer) {
      this.masterLayer = masterLayerCandidate;
      this.emitMasterLayer();
    }
  }

  set setMasterLayer(layer: Layer) {
    this.setCurrentDatetime = layer.nearestTime;
    this.masterLayer = layer;
  }

  get getMasterLayer() {
    return this.masterLayer;
  }

  set setSettingsLayer(layer: Layer) {
    this.settingsLayer = layer;
  }

  get getSettingsLayer() {
    return this.settingsLayer;
  }


  get getCurrentDate() {
    return this.currentDate;
  }

  set setCurrentDate(newDate: string) {
    this.currentDate = newDate;
  }

  get getCurrentTime() {
    return this.currentTime;
  }

  set setCurrentTime(newTime: string) {
    this.currentTime = newTime;
  }

  get getCurrentDateTime() {
    if (this.currentDate === undefined) {
      return undefined;
    }
    return this.currentDate + ' ' + this.currentTime;
  }

  set setCurrentDatetime(datetime: string) {
    if (datetime !== null && datetime !== undefined) {
      this.currentDate = datetime.split('T')[0];
      this.currentTime = datetime.split('T')[1];
    }
  }

  findLayer(layerId: string): Layer {
    return this.layers.find((thisLayer) => thisLayer.id === layerId);
  }

  checkIfDateAvailable(layer: Layer, date: string) {
    const dateObject = moment.utc(date);
    if (layer.availableDates.hasOwnProperty(dateObject.year())) {
      if (layer.availableDates[dateObject.year()].hasOwnProperty(dateObject.month())) {
        if ($.inArray(dateObject.date(), layer.availableDates[dateObject.year()][dateObject.month()]) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  checkIfTimeAvailable(layer: Layer, datetime: string, callbackFunction: Function, fallbackFunction?: Function) {
    const momentDatetime = moment.utc(datetime);
    this.ds.getTimesteps(layer.id, momentDatetime.format('YYYY-MM-DD')).subscribe(timesteps => {
      if ($.inArray(momentDatetime.format('HH:mm:ss.000[Z]'), timesteps) !== -1) {
        callbackFunction();
      } else if (fallbackFunction) {
        fallbackFunction();
      }
    });
  }

  checkIfDatetimeAvailable(layer: Layer, datetime: string, callbackFunction: Function, fallbackFunction?: Function) {
    if (this.checkIfDateAvailable(layer, datetime)) {
      this.checkIfTimeAvailable(layer, datetime, callbackFunction, fallbackFunction);
    } else if (fallbackFunction) {
      fallbackFunction();
    }
  }

  emitSettingsLayer() {
    this.settingsLayerChangedSource.next(this.settingsLayer);
  }

  emitMasterLayer() {
    this.masterLayerChangedSource.next(this.masterLayer);
  }

  emitChangedDate() {
    this.currentDateChangedSource.next(this.currentDate);
  }

  emitChangedTime() {
    this.currentTimeChangedSource.next(this.currentTime);
  }

  emitLayerVisibilityChanged(layer: Layer) {
    this.layerVisibilityChangedSource.next(layer);
  }

  emitSelected(selectedLayer: Layer) {
    this.layerSelectedSource.next(selectedLayer);
  }

  emitZIndexChanged(layer: Layer) {
    this.zIndexChangedSource.next(layer);
  }

  emptyLayers() {
    this.layers = [];
  }

  resetLayers() {
    this.layers.forEach(layer => {
      if (layer.isVisible) {
        layer.isVisible = false;
        if (layer.category !== undefined) {
          layer.category.numVisibleLayers--;
          if (layer.category.numVisibleLayers < 0) {
            layer.category.numVisibleLayers = 0;
          }
          layer.category.hasVisibleLayers = layer.category.numVisibleLayers > 0;
        }
      }

      if (layer.leafletSource) {
        layer.leafletSource = null;
      }
      layer = null;
      this.emitLayerVisibilityChanged(layer);
    });
    this.currentDate = undefined;
    this.currentTime = undefined;
    this.masterLayer = undefined;
    this.settingsLayer = undefined;

    this.emitMasterLayer();
    this.emitChangedDate();
    this.emitChangedTime();
  }
}
