import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';

declare const gtag: any;

export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  action: string
  label: string;
  category: string;
  item: string;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();
  private tracking: boolean

  constructor() {}

  public startTracking(): void {
    gtag('js', new Date());
    gtag('config', environment.GA);
    this.subscribeToEvents();
  }

  private subscribeToEvents(): void {
    this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      if (e.type === 'EVENT') {
        gtag('event', e.action, {
          'event_category': e.category,
          'event_label': e.label,
          'value': e.item
          });
      }
    });
  }

  public trackEvent(action: string, category: string, label: string, item: string) {
    this.eventsQueue$.next({type: 'EVENT', action: action, label: label, category: category, item: item});
  }
}
