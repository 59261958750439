/**
 *
 * @author: tigm
 * @file: A component responsible for drawing charts
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { TutorialService } from '../shared/tutorial.service';
import { ApiService } from '../shared/api.service';
import { LayersService } from '../shared/layers.service'
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';

declare var Highcharts;

export class TimeParameter {
    name: string;
    units: string;
    parent: string;
    data: [Date, number][];
    yAxis: number;
    isOpposite: boolean;
}

@Component({
    selector: 'gp-series-chart',
    templateUrl: './series-chart.component.html',
    styleUrls: ['./series-chart.component.css']
})
export class SeriesChartComponent implements OnInit {
    private chart: any; // A variable to hold chart reference
    private parameters: TimeParameter[]; // Contains a list of parameters to build the chart
    public isChartLoading = false;
    public isChangingRange = false;
    public isDialogOpen = false;

    constructor(public mdDialogRef: MatDialogRef<SeriesChartComponent>, private tutorial: TutorialService,
        private capi: ApiService, private ls: LayersService, private dialog: MatDialog, ) {
        this.parameters = [];
    }

    ngOnInit() {
    };


    public clear() {
        this.parameters = [];
        this.capi.clear();
        this.chart = null;
    }

    /**
   * Adds a parameter (variable) to the chart
   *
   * @param name The variable name
   * @param units The variable units
   * @param data The data to plot
   */
    public addParameter(name: string, parent: string, units: string, data: [Date, number][]): void {
        // If the number of parameters is odd number, place chart axis on the right
        const oppositeYAxis = this.parameters.length % 2 !== 0;
        this.parameters.push({ name: name, units: units, parent: parent, data: data, yAxis: this.parameters.length, isOpposite: oppositeYAxis });
    }

    public removeParameter(name: string, units: string) {
        const parameterToRemove = this.parameters.find(param => param.name === name && param.units === units);
        if (parameterToRemove !== undefined) {
            const param_index = this.parameters.indexOf(parameterToRemove, 0);
            this.parameters.splice(param_index, 1);
        } else {
            console.log('Could not remove parameter')
        }
    }

    public hasParameter(parent: string) {
        const parameter = this.parameters.find(param => param.parent === parent);
        if (parameter !== undefined) {
            return true;
        } else {
            return false;
        }
    }


    /**
     * Helper function to create a yAxis object
     * @param name Name of the axis
     * @param units Units of the axis
     * @param isOpposite If the axis is located on left (false) or right (true)
     */
    private yAxis(name: string, units: string, colorIndex: number, isOpposite?: boolean, ) {
        if (units === "") {
            return {
                title: {
                    text: `${name}`,
                    style: {
                        color: Highcharts.getOptions().colors[colorIndex]
                    }
                },
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[colorIndex]
                    }
                },
                opposite: isOpposite

            };
        } else {
            return {
                title: {
                    text: `${name} (${units})`,
                    style: {
                        color: Highcharts.getOptions().colors[colorIndex]
                    }
                },
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[colorIndex]
                    }
                },
                opposite: isOpposite

            };
        }

    }

    /**
     * Helper function to create a series objeect
     * @param name Name of the series
     * @param data Data to populate the series
     * @param yAxis The yAxis where the series shall be drawn
     */
    private series(name: string, data: [Date, number][], yAxis: number): any {
        return {
            type: 'spline',
            name: name,
            data: data.map(p => {
                return {
                    x: p[0].getTime(),
                    y: p[1]
                };
            }),
            yAxis: yAxis
        };
    }

    /**
     * A Function to initialize the chart
     */
    private drawChart() {
        const _yAxis = this.parameters.map(param => {
            return this.yAxis(param.name, param.units, 0, false);
        });
        const _series = this.parameters.map(param => {
            return this.series(param.name, param.data, 0);
        });
    }

    public draw() {
        let _yAxis;

        _yAxis = this.parameters.map(param => { return this.yAxis(param.name, param.units, param.yAxis, param.isOpposite); });
        const _series = this.parameters.map(param => { return this.series(param.name, param.data, param.yAxis); });
        this.chart = Highcharts.chart('chart', {
            chart: {
                zoomType: 'xy',
            },
            title: {
                text: 'Time series'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: _yAxis,
            legend: {
                enabled: true
            },
            plotOptions: {
                series: {
                    turboThreshold: 0
                }
            },
            series: _series
        });
    };

    set chartLoading(isLoading: boolean) {
        this.isChartLoading = isLoading;
    }

    set changingRange(isChangingRange: boolean) {
        this.isChangingRange = this.isChangingRange;
    }

    get downloadNotSupported() {
        return (this.ls.getMasterLayer.leafletSource instanceof Object) || this.ls.getMasterLayer.isDatasetLayer;
    }

    get status() {
        return this.capi.currentStatus;
    }

    get resultFile() {
        return this.capi.result;
    }

    get masterLayerName() {
        const masterLayer = this.ls.getMasterLayer;

        const layerParameter = this.parameters.find(param => param.parent === masterLayer.id);

        if (layerParameter !== undefined) {
            return layerParameter.name;
        } else {
            return '---';
        }
    }

    downloadData() {
        const masterLayer = this.ls.getMasterLayer;

        const layerParameter = this.parameters.find(param => param.parent === masterLayer.id);

        const startDate = layerParameter.data[0][0].toLocaleDateString('en-GB').replace(/\//g, '-');
        const endDate = layerParameter.data.slice(-2)[0][0].toLocaleDateString('en-GB').replace(/\//g, '-');


        this.isDialogOpen = true
        const dialog = this.dialog.open(DisclaimerComponent);

        dialog.afterClosed().subscribe((flag) => {
            this.isDialogOpen = false;
            if (flag) {
                if (masterLayer.id.lastIndexOf('ts', 0) === -1) {
                    this.capi.invokeProcessor(masterLayer.type, 'insitu', masterLayer.id, startDate, endDate, 'all', 'none', 'zip')
                } else {
                    this.capi.invokeProcessor(masterLayer.type, 'insituTimeSeries', masterLayer.id, startDate, endDate, 'all', 'none', 'zip')
                }
            } else {
                console.log('err')
            }
        });
    }
}
