export class TooltipParameters {
    constructor(
        public isVisible: boolean,
        public time: Date,
        public top: string,
        public left: string,
        public lat: string,
        public lng: string,
        public infoData: any[],
        public point: any
    ) {}
}
