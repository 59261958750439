import { Layer } from './layer';
import { ParentCategory } from './parent-category';

export class Category {
    constructor(
        public id: string,
        public name: string,
        public icon: string,
        public description: string,
        public layers: Layer[],
        public zIndex: number,
        public bounds: number[],
        public parentCategory: ParentCategory,
        public currentState?: string,
        public listPosition?: number,
        public group?: string,
        public numVisibleLayers?: number,
        public isDisabled?: boolean,
        public biological?: string,
        public environmental?: string,
        public categoryAbove?: string,
        public biologicalCategory?: Category[],
        public environmentalCategory?: Category[],
        public hasVisibleLayers?: boolean,
        public temporaryCoords?: number[]

    ) {
        /*this.numVisibleLayers = 0;
        for (const entry of this.layers) {
           if (entry.isVisible) {
               this.numVisibleLayers += 1;
           }
        }
        */
    }

    addLayer(layer: Layer) {
        const existentLayer = this.findLayer(layer.id);
        if (!existentLayer) {
            this.layers.unshift(layer);
        }
    }

    findLayer(layerId: string): Layer {
        return this.layers.find((thisLayer) => thisLayer.id === layerId);
    }

    addEnvironmentalCategory(category: Category) {
        const existentCategory = this.findEnvironmentalCategory(category.id);
        if (!existentCategory) {
            this.environmentalCategory.unshift(category);
        }
    }

    findEnvironmentalCategory(categoryId: string): Category {
        return this.environmentalCategory.find((thisCategory) => thisCategory.id === categoryId);
    }

    addBiologicalCategory(category: Category) {
        const existentCategory = this.findBiologicalCategory(category.id);
        if (!existentCategory) {
            this.biologicalCategory.unshift(category);
        }
    }

    findBiologicalCategory(categoryId: string): Category {
        return this.biologicalCategory.find((thisCategory) => thisCategory.id === categoryId);
    }
}
