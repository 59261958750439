import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MapService } from '../shared/map.service';
import { LayersService } from '../shared/layers.service';
import { CategoriesService } from '../shared/categories.service';
import * as L from 'leaflet';
// declare var L: any;
declare var $: any;

@Component({
  selector: 'gp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit {
  constructor(private mapService: MapService, private layersService: LayersService,
    private categoriesService: CategoriesService) {
      // this.isLoaderActive = mapService.isLoading;
      mapService.loadingChanged$.subscribe(status => {
        this.mapService.isLoaderActive = status;
      });
     }

  ngOnInit() {
    // this.mapService.createMap('map');
    // this.mapService.addBasemap();
  }

  ngAfterViewInit() {
    this.mapService.createMap('map');
    this.mapService.addBasemap("satellite");
  }
}
