import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/Rx';
import { GpSettings } from './shared/gp-settings';

@Injectable()
export class DatasetsService {
  constructor(private http: Http) {
  }

  getCatalogueMetadata() {
    return this.http.get('https://coastnet-32f78.firebaseio.com/groups.json').map(
      (response: Response) => response.json());
  }
  getAvailableLayers() {
    return this.http.get(GpSettings.NCWMS_URL + '?request=GetMetadata&item=menu').map(
      (response: Response) => response.json());
  }

  getLayerDetails(layerId: string) {
    return this.http.get(GpSettings.NCWMS_URL + '?request=GetMetadata&item=layerDetails&layerName=' + layerId).map(
      (response: Response) => response.json());
  }

  getTimesteps(layerId: string, date: string) {
    return this.http.get(GpSettings.NCWMS_URL + '?request=GetMetadata&item=timesteps&layerName=' + layerId + '&day=' + date).map(
      (response: Response) => response.json().timesteps);
  }

}
